import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MessagingService {

    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging) {
        this._bind();
    }

    private _bind(): void {
        if (!environment.production) {
            return;
        }

        this.angularFireMessaging.messaging
            .subscribe(
                (_messaging) => {
                    _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                    _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);

                    // // Customize notification here
                    // const notificationTitle = 'Background Message Title';
                    // const notificationOptions = {
                    //     body: 'Background Message body.',
                    //     icon: '/firebase-logo.png'
                    // };
                    //
                    // return _messaging.registration.showNotification(notificationTitle, notificationOptions);
                }
            );
    }

    requestToken(): Observable<string | null> {
        if (!environment.production) {
            return of(null);
        }

        return this.angularFireMessaging.requestToken;
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage(): void {
        if (!environment.production) {
            return;
        }

        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);
            });
    }
}