import {Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';

import {FuseNavigation, FuseNavigationItem} from '@fuse/types';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {ThemeConfigService} from '@sip/common/config/theme-config.service';
import {MessagingService} from '@sip/common/services/messaging.service';
import {SessionPrefetchService} from '@sip/common/services/session-prefetch.service';
import {ActionPanelEnum, SessionModel, SessionPanel} from '@sip/common/models';

import {Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, takeUntil} from 'rxjs/operators';

import {PostService} from '../../../shared/services/post.service';
import {SessionConfigService} from '../../../shared/services/session-config.service';
import {ParticipantModel} from '../../../shared/models/partipant.model';
import {AlertAllParticipants, EventPayload, ProjectChangedConfig} from '../../../shared/events';
import {ShowMsgComponent} from './show-msg/show-msg.component';

import {environment} from '../../../../environments/environment';
import {navigationAuth, navigationGuest} from '../../../navigation';
import {ParticipantService} from '../../../shared/services/participant.service';
import {ChatStoreService} from '../../../shared/services/chat.store.service';
import {ClearDataEvent} from '../../../shared/services/events';
import {VoteService} from '../../../shared/services/vote.service';


@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {

    @Input() layout;

    // Public
    public session: SessionModel;
    public participant: ParticipantModel;
    public fusePerfectScrollbarUpdateTimeout: any;
    public navigation: any;
    public versionSystem: string;

    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    constructor(public config: SessionConfigService,
                private _fuseNavigationService: FuseNavigationService,
                private _fuseSidebarService: FuseSidebarService,
                private _router: Router,
                private _messageService: MessagingService,
                private _me: ParticipantService,
                private _postService: PostService,
                private _sessionPrefetchService: SessionPrefetchService,
                private _chatStore: ChatStoreService,
                private _sipThemeStore: ThemeConfigService,
                private _votService: VoteService,
                public dialog: MatDialog) {
        // Set the defaults
        this.layout = 'vertical';

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.versionSystem = environment.APP_VERSION;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective)
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        this._fuseNavigationService.onItemCollapseToggled
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
                    this._fusePerfectScrollbar.update();
                }, 310);
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.config.session$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(session => {
                this.session = session;
                this.checkTypeView();
                this.checkPages();

                if (session) {
                    this._sipThemeStore.requestSessionConfigTheme(session.code);
                    this.checkAssetForFetch(session.code);
                }
            });

        this.config.participant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(participant => {

                this.participant = participant;
                this.checkPages();
                this.setNavigation();
                this.registerTokenFromUser();

            });

        // LISTENER NEW MSG ALERT
        this.config.broadCast$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((e: EventPayload): e is AlertAllParticipants => e instanceof AlertAllParticipants)
            )
            .subscribe((e: AlertAllParticipants) => {
                // mytodo custon alert -> alertMessage
                if (!e || !e.message) {
                    return;
                }
                this.showAlert(e.message);
            });

        // LISTENER NEW THEME
        this._sipThemeStore.bind();
        this.config.broadCast$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((e: EventPayload): e is ProjectChangedConfig => e instanceof ProjectChangedConfig)
            )
            .subscribe((e: ProjectChangedConfig) => {
                this._sipThemeStore.setTheme(e.config);
            });

        // LISTENER ON SESSION PANEL
        this.config.broadCast$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((e): e is SessionPanel => e instanceof SessionPanel)
            )
            .subscribe((e: SessionPanel) => {
                switch (e.action) {
                    case ActionPanelEnum.closeSession:
                        this._router.navigate(['/sessao/fim']);
                        break;
                    case ActionPanelEnum.resetCacheAssets:
                        this.checkAssetForFetch(this.session.code);
                        break;
                }
            });

        // LISTENER ON SESSION PANEL
        this.config.broadCast$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((e): e is ClearDataEvent => e instanceof ClearDataEvent)
            )
            .subscribe(() => {
                this._votService.clearAllVotes();
            });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if (this._fuseSidebarService.getSidebar('navbar')) {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(filter(value => value !== null))
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                this.checkTypeView();
            });


        // LISTENER POSTS AND ADD NAV
        this._postService.state$
            .pipe(
                map(state => state.posts),
                distinctUntilChanged(),
                takeUntil(this._unsubscribeAll),
            ).subscribe(_ => this.addNavigationsByPostService());


        this._chatStore.onChangeUnread()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(n => {
                const nav: FuseNavigation = this._fuseNavigationService.getNavigationItem('perguntar');
                if (nav) {
                    nav.badge = !n ? null : {
                        title: n.toString(),
                        bg: '#F44336',
                        fg: '#FFFFFF'
                    };
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

    alertMessage(msg): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '300px';
        dialogConfig.data = msg;

        this.dialog.open(ShowMsgComponent, dialogConfig)
            .afterClosed().subscribe(result => {
        });
    }

    goToHome(): void {
        this._router.navigateByUrl('session');
        // this._router.navigate([`${this.config.code}/`]);
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded():
        void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }

    private setNavigation(): void {
        if (this.participant) {
            this.navigation = [...navigationAuth];
            this._fuseNavigationService.setCurrentNavigation('authenticated');
        } else {
            this.navigation = [...navigationGuest];
            this._fuseNavigationService.setCurrentNavigation('main');
        }
    }

    private showAlert(message: string): void {
        this.alertMessage(message);

        // Verifica se o browser suporta notificações
        if (!('Notification' in window)) {
            console.log('Este browser não suporta notificações de Desktop');
            return;
        }

        if (environment.production === false) {
            console.log('Rodando em modo dev, por isso não será exibido alert');
            return;
        }

        Notification.requestPermission((result) => {
            if (result === 'granted') {
                this.spawnNotification(message);
            }
        });
    }

    private checkTypeView(): void {
        if (!this.session) {
            return;
        }

        const calendarNavItem = this._fuseNavigationService.getNavigationItem('views');
        const passwordNavItem = this._fuseNavigationService.getNavigationItem('password');

        if (calendarNavItem) {
            calendarNavItem.hidden = this.session.view !== 'all';
        }

        if(passwordNavItem){
            passwordNavItem.hidden = this.session.identify === 'anonymous' || this.session.identify === 'public';
        }
    }

    private spawnNotification(message: string): void {
        // https://web-push-book.gauntface.com/chapter-05/02-display-a-notification/#icon
        // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification
        navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification('SIP - avisos:', {
                body: message,
                icon: '/app/assets/images/logos/logo-sm-black.png',
                tag: 'sip'
            });
        });
    }

    private registerTokenFromUser(): void {
        if (!this.participant) {
            return;
        }

        this._messageService.requestToken()
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(token => !!token),
                mergeMap(token => this._me.update({'fcmToken': token}))
            )
            .subscribe(r => console.log('Token UPDATED', r));
    }

    private checkPages(): void {
        if (!this.session) {
            return;
        }

        if (this.participant) {
            this._postService.loadPagesAuth(this.session.code);
        } else {
            this._postService.loadPagesGuest(this.session.code);
        }
    }

    private checkAssetForFetch(code: string): void {
        this._sessionPrefetchService.startPrefetchAssets(code, 'participant');
    }

    private addNavigationsByPostService(): void {
        const navs: FuseNavigationItem[] = this._fuseNavigationService.getCurrentNavigation();
        this.setNavigation();
        // console.log('addNavigationsByPostService', this._postService.getNavigations(`/${this.session.code}`));

        // navs.forEach(m => {
        //     if (m.id.includes('post-')) {
        //         console.log('REMOVEEE', m);
        //         this._fuseNavigationService.removeNavigationItem(m.id);
        //     }
        // });

        const urlInit = this.session ? `/${this.session.code}` : '';

        this._postService.getNavigations(urlInit)
            .map(p => {
                if (!navs.find(n => n.id === p.id)) {
                    this._fuseNavigationService.addNavigationItem(p, 'end');
                }
            });
    }
}
