import {version} from './version';

export interface EnvironmentType {
    production: boolean;
    hmr: boolean;
    URL_API: string;
    URL_APP: string;
    SOCKET_APP_KEY: string;
    SOCKET_URL_AUTH: string;
    SOCKET_HOST: string;
    SOCKET_FORCE_TLS: boolean;
    SOCKET_PORT: number | null;
    UPLOAD_TOKEN: string;
    ENVIRONMENT: string;
    DEBUG: boolean;
    APP_VERSION: string;
    firebase: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
    };
}

export const environmentBase: EnvironmentType = {
    production: false,
    hmr: false,
    URL_API: null,
    URL_APP: null,
    SOCKET_APP_KEY: null,
    SOCKET_URL_AUTH: null,
    SOCKET_HOST: null,
    SOCKET_PORT: null,
    SOCKET_FORCE_TLS: true,
    UPLOAD_TOKEN: '',
    ENVIRONMENT: 'base',
    DEBUG: false,
    APP_VERSION: version,
    firebase: {
        apiKey: 'AIzaSyB8LAGDTcr1EeO5sLfbvtDvhcOI3acriG8',
        authDomain: 'sip-vis.firebaseapp.com',
        databaseURL: 'https://sip-vis.firebaseio.com',
        projectId: 'sip-vis',
        storageBucket: 'sip-vis.appspot.com',
        messagingSenderId: '818846616972',
        appId: '1:818846616972:web:4a3379a1671e7328'
    }
};
