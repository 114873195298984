import {SessionModel} from '@sip/common/models';

export enum ParticipantRoleEnum {
    VOTER = 'VOTER',
    UNIT = 'UNIT',
    REPRESENTATIVE = 'REPRESENTATIVE',
}

export interface ParticipantModel {
    id: number;
    session_id: number;
    personal_key: string;
    name: string;
    login: string;
    role: ParticipantRoleEnum | null;
    weight?: any;
    group?: any;
    test_type?: any;
    metas?: any;
    extra_columns?: any;
    last_activity?: any;
    session?: SessionModel;
    created_at: string;
    updated_at: string;
    force_update_pwd?: boolean;
}

export interface UnitModel {
    id: number;
    login: string;
    name: string;
    personal_key: string;
    group: string | null;
    role: string;
    session_id: number;
    blocked: boolean;
    weight: number;
    weight_enabled: boolean;
}

export interface IQuestion {
    id: number;
    slide_id: number;
    title: string;
    timer: number | null;
    timer_stop: boolean;
    open: boolean;
    type: string;
    weight: boolean;
    test_type: string | null;
    change_vote: boolean;
}

export interface IUnitWrapper {
    unit: UnitModel;
    already_voted: boolean;
    votes: string[] | null;
}

export interface IRepresentativeResponse {
    quantity: number;
    question: IQuestion | null;
    weight_total: number;
    unities: IUnitWrapper[];
}

export interface IRepresentativeVoteDataResponse {
    unit: UnitModel;
    vote_hash: string;
}

export interface IRepresentativeVoteResponse {
    status: 'success' | 'error';
    message: string;
    data: IRepresentativeVoteDataResponse[];
}

export interface IRepresentativeVoteDto {
    question_id: number;
    start_vote: number;
    end_vote: number;
    view: 'slide' | 'keypad';
    chosen_response: string[];
    text_response: string;
    unities: number[];
}
