<div *ngIf="msg" class="dialog-content-wrapper dialog-form-project p-0">

    <mat-toolbar matDialogTitle class="mat-accent m-0" mat-dialog-title>
        <div matDialogTitle fxFlex fxLayout="row" fxLayoutAlign="space-between center" style="height: 62px;">
            <span class="title dialog-title">Atenção</span>
            <button mat-button class="mat-icon-button"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>

        <p class="font-size-20">{{msg}}</p>

    </div>
    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-button
                color="warn"
                (click)="dialogRef.close()"
                aria-label="Delete"
                class="mr-8"
                matTooltip="Delete">
            <mat-icon color="warn">close</mat-icon>
            Fechar
        </button>


    </div>
</div>
