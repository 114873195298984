import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {ThemeConfigService} from './theme-config.service';
import {Subscription} from 'rxjs';


export enum LogoManagerTypeEnum {
    PRIMARY_LOGO = 'primary_logo',
    SECONDARY_LOGO = 'secondary_logo'
}

@Directive({
    selector: '[sipCommomLogoManager]',
    // providers: [ConfigService]
})
export class LogoManagerDirective implements OnInit, OnDestroy, OnChanges {
    @Input() sipCommomLogoManager: LogoManagerTypeEnum;

    private _subject: Subscription;

    constructor(public elem: ElementRef, public renderer: Renderer2, private _store: ThemeConfigService) {
    }

    ngOnInit(): void {
        this._changes();

        this._subject = this._store.selectOnChangeUiid()
            .subscribe(_ => this._changes());
    }

    ngOnDestroy(): void {
        this._subject.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this._changes();
    }

    private _changes(): void {
        switch (this.sipCommomLogoManager) {
            case LogoManagerTypeEnum.PRIMARY_LOGO:
                this.renderer.setProperty(this.elem.nativeElement, 'src', this._store.getLogos().primaryLogo);
                break;

            case LogoManagerTypeEnum.SECONDARY_LOGO:
                this.renderer.setProperty(this.elem.nativeElement, 'src', this._store.getLogos().secondaryLogo);
                break;

            default:
                console.error('Invalid type in LogoManagerDirective->', this.sipCommomLogoManager);
        }
    }

}
