var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { W3StorageService } from '@rapi/w3/apps/storage';
import { W3AuthAbstractService, W3MeService } from '@rapi/w3/apps/auth';
import { map, shareReplay, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { SessionConfigService } from './session-config.service';
import { environment } from '../../../environments/environment';
var AuthService = /** @class */ (function (_super) {
    __extends(AuthService, _super);
    function AuthService(config, http, storage, me) {
        var _this = _super.call(this, http, storage, me) || this;
        _this.config = config;
        return _this;
    }
    /**
     * @param {string} login
     * @param {string} password
     * @param {string} code session code
     * @returns {Observable<string>}  status [error, success]
     */
    AuthService.prototype.login = function (login, password, code) {
        var _this = this;
        var data = { login: login, password: password, code: code, token: this.getDeviceToken() };
        return this.http
            .post(environment.URL_API + "/mod/participants/auth/login", data)
            .pipe(tap(function (res) { return _this.setSession(res); }), map(function (r) { return r.status; }), shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
        );
    };
    AuthService.prototype.loginPublic = function (name, code) {
        var _this = this;
        var data = { name: name, code: code, token: this.getDeviceToken() };
        return this.http
            .post(environment.URL_API + "/mod/participants/auth/sign-up-public", data)
            .pipe(tap(function (res) { return _this.setSession(res); }), map(function (r) { return r.status; }), shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
        );
    };
    AuthService.prototype.register = function (data) {
        var _this = this;
        data.token = this.getDeviceToken();
        return this.http
            .post(environment.URL_API + "/mod/participants/auth/sign-up-private", data)
            .pipe(tap(function (res) { return _this.setSession(res); }), map(function (r) { return r.status; }), shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
        );
    };
    AuthService.prototype.logout = function () {
        var refreshToken = this.storage.get('access_token');
        var options = {
            headers: { Authorization: "Bearer " + refreshToken },
        };
        this.storage.remove('access_token');
        this.storage.remove('expires_at');
        this.config.dispatchClearData();
        return this.http
            .post(environment.URL_API + "/mod/participants/auth/logout", null, options)
            .pipe(map(function (r) { return r.status; }));
    };
    AuthService.prototype.forceLogout = function () {
        this.clearToken();
        window.location.href = '/app';
    };
    AuthService.prototype.setSession = function (authResult) {
        var expiresAt = moment().add(authResult.data.expires_in, 'second');
        this.storage.set('access_token', authResult.data.access_token);
        this.storage.set('expires_at', JSON.stringify(expiresAt.valueOf()));
    };
    AuthService.prototype.getUrlRefreshToken = function () {
        return environment.URL_API + "/mod/participants/auth/refresh";
    };
    AuthService.prototype.setDeviceToken = function (token) {
        window.localStorage.setItem('device_token', token);
    };
    AuthService.prototype.getDeviceToken = function () {
        var token = window.localStorage.getItem('device_token');
        token = token ? token : this.uuid();
        this.setDeviceToken(token);
        return token;
    };
    AuthService.prototype.uuid = function () {
        var s4 = function () { return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1); };
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    AuthService.prototype.requestSessionConfigOptIn = function (code) {
        return this.http
            .post(environment.URL_API + "/mod/participants/sessions/config/theme", { code: code })
            .pipe(map(function (res) { return res.data.opt_in; }));
    };
    return AuthService;
}(W3AuthAbstractService));
export { AuthService };
