var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ToastrService } from 'ngx-toastr';
import { W3MessagesLv56ResponseService } from '@rapi/w3/interceptor/messages-lv56-response.service';
import { environment } from '../../../environments/environment';
/**
 * https://github.com/scttcper/ngx-toastr
 */
var SIPMessagesLv56ResponseService = /** @class */ (function (_super) {
    __extends(SIPMessagesLv56ResponseService, _super);
    function SIPMessagesLv56ResponseService(_toast) {
        return _super.call(this, _toast) || this;
    }
    SIPMessagesLv56ResponseService.prototype.respondOk = function (data) {
        if (!data.message) {
            return;
        }
        switch (data.status) {
            case 'success':
                if (this.isDev()) {
                    this._toast.success('DEV: ' + data.message);
                }
                break;
            case 'error':
                this._toast.error(data.message);
                break;
            default:
                this._toast.info(data.message);
        }
    };
    SIPMessagesLv56ResponseService.prototype.isDev = function () {
        return environment.DEBUG;
    };
    return SIPMessagesLv56ResponseService;
}(W3MessagesLv56ResponseService));
export { SIPMessagesLv56ResponseService };
