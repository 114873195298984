var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { VoteHashDialogComponent } from '../components/vote-hash-dialog/vote-hash-dialog.component';
import { SessionConfigService } from './session-config.service';
import { QuestTypeEnum } from '../models/session.model';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./session-config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/material/dialog";
var VoteService = /** @class */ (function () {
    function VoteService(_config, http, dialog) {
        this._config = _config;
        this.http = http;
        this.dialog = dialog;
        this._votes = new Map();
    }
    VoteService.prototype.setSlide = function (slide) {
        this._slide = slide;
        this.setDictionary(this._slide.getDictionary());
        if (this._dialogRef) {
            this.dialog.closeAll();
        }
    };
    VoteService.prototype.setQuestion = function (q) {
        this._question = q;
        this.reset();
        this._p = this.getSelectChoices();
    };
    VoteService.prototype.changeVote = function () {
        return this._question.changeVote;
    };
    VoteService.prototype.isTextOrNumerical = function () {
        return ['text', 'numerical'].includes(this._question.type);
    };
    VoteService.prototype.isLongPoll = function () {
        return ['long_poll'].includes(this._question.type);
    };
    VoteService.prototype.isChangedVote = function (alternative) {
        var v = this.getCurrentVote();
        if (!v) {
            return true;
        }
        else if (this._question.type === 'text') {
            return v.text_response !== alternative;
        }
        else if (this._question.type === 'numerical') {
            return v.chosen_response.length > 0 && v.chosen_response[0] !== alternative;
        }
        else {
            return true; // sempre ira retornar true para POLL
        }
    };
    VoteService.prototype.press = function (alternative, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        if (!this._start) {
            return;
        }
        if (this._question.type === 'text') {
            return this.pressText(alternative, showConfirmation);
        }
        else if (this._question.type === 'numerical') {
            return this.pressNumerical(alternative, showConfirmation);
        }
        else {
            return this.pressPoll(alternative, showConfirmation);
        }
    };
    VoteService.prototype.pressFromKeyPad = function (responses, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        if (!this._start) {
            return;
        }
        switch (this._question.type) {
            case 'text':
            case 'numerical':
                this.press(responses[0], showConfirmation);
                break;
            case 'poll':
                this._p = responses;
                this.confirm(true, showConfirmation);
        }
    };
    VoteService.prototype.pressLongPoll = function (alternatives, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        this._p = alternatives;
        return this.confirm(true, showConfirmation);
    };
    VoteService.prototype.pressPoll = function (alternative, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        var i = this._p.indexOf(alternative);
        if (i !== -1) {
            this._p.splice(i, 1);
        }
        else if (this._p.length === this._question.choices) {
            this._p.shift();
            this._p.push(alternative);
        }
        else {
            this._p.push(alternative);
        }
        if (this._slide) {
            this._slide.selectAlternative(this._p);
        }
        return this.confirm(true, showConfirmation);
    };
    VoteService.prototype.pressText = function (response, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        this._text_response = response;
        return this.confirm(true, showConfirmation);
    };
    VoteService.prototype.pressNumerical = function (response, showConfirmation) {
        if (showConfirmation === void 0) { showConfirmation = false; }
        this._p = [response];
        return this.confirm(true, showConfirmation);
    };
    VoteService.prototype.reset = function () {
        this._p = [];
        this._text_response = null;
    };
    VoteService.prototype.startVoting = function () {
        this._start = new Date;
    };
    VoteService.prototype.started = function () {
        return !!this._start;
    };
    VoteService.prototype.stopVoting = function () {
        this._start = null;
    };
    VoteService.prototype.confirm = function (checkConfirm, showConfirmation) {
        var _this = this;
        if (checkConfirm === void 0) { checkConfirm = true; }
        if (showConfirmation === void 0) { showConfirmation = false; }
        if ((checkConfirm && this._confirmVote) || this._config.roleIsRepresentative()) {
            return Promise.resolve(null);
        }
        var data = this.makeBallot();
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.URL_API + "/mod/participants/votes", data)
                .subscribe(function (result) {
                if (showConfirmation) {
                    _this._showVoteHashDialog(result.data);
                }
                _this.setVotes([result.data]);
                resolve();
            }, function () {
                _this.errorOnVote();
                reject();
            });
        });
    };
    VoteService.prototype.makeBallot = function () {
        return {
            'question_id': this._question.questId,
            'start_vote': this._start.getTime(),
            'end_vote': (new Date).getTime(),
            'chosen_response': this._p,
            'text_response': this._text_response,
            'view': this._config.info.view
        };
    };
    VoteService.prototype.errorOnVote = function () {
        this._p = this.getSelectChoices();
        if (this._slide) {
            this._slide.selectAlternative(this._p);
        }
    };
    VoteService.prototype.getInput = function () {
        var _this = this;
        var polls = this._p.map(function (label) { return ({
            empty: false,
            label: label,
            value: _this._dictionary[label] ? _this._dictionary[label] : ''
        }); });
        var rest = this._question.choices - polls.length;
        while (rest > 0) {
            rest--;
            polls.push({ empty: true, label: null, value: null });
        }
        return {
            inputType: this.getInputType(),
            reply_poll: polls,
            reply_text: this._text_response || this._p[0]
        };
    };
    VoteService.prototype.getInputType = function () {
        if (this.isTextOrNumerical()) {
            return QuestTypeEnum.QUEST_TEXT;
        }
        if (this.isLongPoll()) {
            return QuestTypeEnum.QUEST_LONG_POLL;
        }
        return QuestTypeEnum.QUEST_POLL;
    };
    VoteService.prototype.getCurrentVote = function () {
        return this._votes.get(this._question.questId);
    };
    VoteService.prototype.setVotes = function (votes) {
        var _this = this;
        votes.forEach(function (v) { return _this._votes.set(v.question_id, v); });
    };
    VoteService.prototype.clear = function (questId) {
        this.reset();
        this._votes.delete(questId);
    };
    VoteService.prototype.clearAllVotes = function () {
        this.reset();
        this._votes.clear();
    };
    VoteService.prototype.getSelectChoices = function () {
        if (!this._question) {
            return [];
        }
        var voted = this.getCurrentVote();
        return voted && voted.chosen_response ? voted.chosen_response.slice() : [];
    };
    VoteService.prototype.getSelectTextResponse = function () {
        if (!this._question) {
            return null;
        }
        var voted = this.getCurrentVote();
        if (this._question.type === 'numerical') {
            return voted && voted.chosen_response.length > 0 ? voted.chosen_response[0] : null;
        }
        return voted ? voted.text_response : null;
    };
    VoteService.prototype.getSelectKeyPadTextResponse = function () {
        if (!this._question) {
            return null;
        }
        var voted = this.getCurrentVote();
        if (this._question.type === 'poll') {
            return this.getSelectChoices();
        }
        else if (this._question.type === 'numerical') {
            return voted && voted.chosen_response.length > 0 ? [voted.chosen_response[0]] : [];
        }
        else {
            return voted ? [voted.text_response] : [];
        }
    };
    VoteService.prototype.requestMyVotes = function () {
        var _this = this;
        this.http.get(environment.URL_API + "/mod/participants/votes")
            .subscribe(function (result) { return _this.setVotes(result.data); });
    };
    VoteService.prototype.enableConfirmVote = function () {
        this._confirmVote = true;
    };
    VoteService.prototype.setDictionary = function (dictionary) {
        this._dictionary = dictionary;
    };
    VoteService.prototype.hasVoted = function () {
        var v = this.getCurrentVote();
        if (!v) {
            return false;
        }
        if (v.text_response) {
            return true;
        }
        return v.chosen_response && v.chosen_response.length > 0;
    };
    VoteService.prototype.getQuestion = function () {
        return this.asQuestion() ? __assign({}, this._question) : null;
    };
    VoteService.prototype.asQuestion = function () {
        return this._question && this._question.exists;
    };
    VoteService.prototype._showVoteHashDialog = function (data) {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = data;
        dialogConfig.disableClose = true;
        this._dialogRef = this.dialog.open(VoteHashDialogComponent, dialogConfig);
    };
    VoteService.ngInjectableDef = i0.defineInjectable({ factory: function VoteService_Factory() { return new VoteService(i0.inject(i1.SessionConfigService), i0.inject(i2.HttpClient), i0.inject(i3.MatDialog)); }, token: VoteService, providedIn: "root" });
    return VoteService;
}());
export { VoteService };
