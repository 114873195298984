import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { SessionConfigService } from '../services/session-config.service';
import { ParticipantService } from '../services/participant.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/participant.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/session.service";
import * as i4 from "../services/session-config.service";
/**
 * Verifica se Participant esta logado e carrega a sessão
 */
var ParticipantSessionGuard = /** @class */ (function () {
    function ParticipantSessionGuard(_participantService, _router, _sessionService, _config) {
        this._participantService = _participantService;
        this._router = _router;
        this._sessionService = _sessionService;
        this._config = _config;
    }
    ParticipantSessionGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (this._config.check()) {
            return true;
        }
        return this._participantService
            .me('session.event')
            .toPromise()
            .then(function (s) { return _this._activate(s); }, function () { return _this._redirect(); });
    };
    /**
     * CanActivateChild handler
     */
    ParticipantSessionGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    ParticipantSessionGuard.prototype._activate = function (participant) {
        this._config.activate(participant.session);
        this._config.setParticipant(participant);
        if (participant.force_update_pwd) {
            this._router.navigateByUrl('/sessao/redefinir-senha');
        }
        return true;
    };
    ParticipantSessionGuard.prototype._redirect = function () {
        this._router.navigateByUrl('/erro');
        return false;
    };
    ParticipantSessionGuard.ngInjectableDef = i0.defineInjectable({ factory: function ParticipantSessionGuard_Factory() { return new ParticipantSessionGuard(i0.inject(i1.ParticipantService), i0.inject(i2.Router), i0.inject(i3.SessionService), i0.inject(i4.SessionConfigService)); }, token: ParticipantSessionGuard, providedIn: "root" });
    return ParticipantSessionGuard;
}());
export { ParticipantSessionGuard };
