import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'sip-show-msg',
  templateUrl: './show-msg.component.html'
  // styleUrls: ['./show-msg.component.scss']
})
export class ShowMsgComponent{
    public msg = '';

  constructor(public dialogRef: MatDialogRef<ShowMsgComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
      this.msg = data;
  }


    close(): void {
        this.dialogRef.close();
    }
}
