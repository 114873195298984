/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar.component";
import * as i3 from "@angular/animations";
import * as i4 from "../../services/config.service";
import * as i5 from "../../services/match-media.service";
import * as i6 from "./sidebar.service";
import * as i7 from "@angular/flex-layout";
var styles_FuseSidebarComponent = [i0.styles];
var RenderType_FuseSidebarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseSidebarComponent, data: {} });
export { RenderType_FuseSidebarComponent as RenderType_FuseSidebarComponent };
export function View_FuseSidebarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_FuseSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-sidebar", [], [[2, "open", null], [2, "locked-open", null], [2, "unfolded", null], [2, "animations-enabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FuseSidebarComponent_0, RenderType_FuseSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i2.FuseSidebarComponent, [i3.AnimationBuilder, i1.ChangeDetectorRef, i1.ElementRef, i4.FuseConfigService, i5.FuseMatchMediaService, i6.FuseSidebarService, i7.ObservableMedia, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).opened; var currVal_1 = i1.ɵnov(_v, 1).isLockedOpen; var currVal_2 = i1.ɵnov(_v, 1).unfolded; var currVal_3 = i1.ɵnov(_v, 1)._animationsEnabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var FuseSidebarComponentNgFactory = i1.ɵccf("fuse-sidebar", i2.FuseSidebarComponent, View_FuseSidebarComponent_Host_0, { name: "name", key: "key", position: "position", lockedOpen: "lockedOpen", invisibleOverlay: "invisibleOverlay", folded: "folded" }, {}, ["*"]);
export { FuseSidebarComponentNgFactory as FuseSidebarComponentNgFactory };
