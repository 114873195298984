/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./btn-toggle-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./btn-toggle-sidebar.component";
import * as i12 from "../../../../@fuse/components/sidebar/sidebar.service";
var styles_BtnToggleSidebarComponent = [i0.styles];
var RenderType_BtnToggleSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BtnToggleSidebarComponent, data: {} });
export { RenderType_BtnToggleSidebarComponent as RenderType_BtnToggleSidebarComponent };
export function View_BtnToggleSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "navbar-toggle-button"], ["fxHide.gt-md", ""], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpen("navbar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 737280, null, 0, i3.ShowHideDirective, [i4.MediaMonitor, [8, null], i1.ElementRef, i4.StyleUtils, i1.PLATFORM_ID, [2, i4.SERVER_TOKEN]], { hideGtMd: [0, "hideGtMd"] }, null), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "secondary-text mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(4, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["menu"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 1, 0, currVal_2); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).inline; _ck(_v, 3, 0, currVal_3); }); }
export function View_BtnToggleSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sip-shared-btn-toggle-sidebar", [], null, null, null, View_BtnToggleSidebarComponent_0, RenderType_BtnToggleSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i11.BtnToggleSidebarComponent, [i12.FuseSidebarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BtnToggleSidebarComponentNgFactory = i1.ɵccf("sip-shared-btn-toggle-sidebar", i11.BtnToggleSidebarComponent, View_BtnToggleSidebarComponent_Host_0, {}, {}, []);
export { BtnToggleSidebarComponentNgFactory as BtnToggleSidebarComponentNgFactory };
