import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {distinctUntilKeyChanged, map, tap} from 'rxjs/operators';

import {ItemResponse} from '@rapi/w3';
import {W3StorageService} from '@rapi/w3/apps/storage';

import {SimpleStore} from '../services/simple-store.service';
import {ThemeConfigColors, themeConfigDefault, ThemeConfigLogos, ThemeConfigMessages, ThemeConfigState} from './config.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThemeConfigService extends SimpleStore<ThemeConfigState> {

    private _binder = false;

    constructor(
        private _http: HttpClient,
        private _store: W3StorageService
    ) {
        super(themeConfigDefault);

        this.debug('ThemeConfigState');
    }

    bind(): void {
        if (this._binder) {
            throw new Error('Has started bind!');
        }

        this._binder = true;

        const themeFreeze = this._store.get('theme_freeze');

        if (themeFreeze) {
            this.setTheme(JSON.parse(themeFreeze));
        }

        // setTimeout(() => this.setSkinPink(), 2000);
        // setTimeout(() => this.setSkinGreen(), 4000);
        // setTimeout(() => this.setLogoTest(), 2000);

        this.selectOnChangeUiid()
            .subscribe(state => {
                this.updateStyle();
                this.updateBackgrounds();
                this._store.set('theme_freeze', JSON.stringify(state));
            });
    }

    selectOnChangeUiid(): Observable<ThemeConfigState> {
        return this.state$
            .pipe(distinctUntilKeyChanged('uuid'));
    }

    getUiid(): string {
        return this.state.uuid;
    }

    getColors(): ThemeConfigColors {
        return this.state.colors;
    }

    getMessages(): ThemeConfigMessages {
        return this.state.messages;
    }

    getLogos(): ThemeConfigLogos {
        return this.state.logos;
    }

    updateStyle(): void {
        const colors = this.getColors();

        Object.keys(colors).forEach(property => {
            document.documentElement.style.setProperty(
                `--${property}`,
                colors[property]
            );
        });
    }

    updateBackgrounds(): void {
        const logos = this.getLogos();

        Object.keys(logos).forEach(property => {
            document.documentElement.style.setProperty(
                `--${property}`,
                `url(${logos[property]}`
            );
        });
    }

    setLogoTest(): void {
        this.setState({
            ...this.state,
            uuid: 'changed_log',
            logos: {
                ...this.state.logos
            }
        });
    }

    setSkinPink(): void {
        this.setTheme(this.getSkinPink());
    }

    getSkinPink(): ThemeConfigState {
        return {
            ...this.state,
            uuid: 'pink',
            colors: {
                // Primary
                primaryBgColor: '#DF5286',
                primaryBgColorDark: '#DF5286',
                primaryBgColorLight: '#E0115F',
                primaryFgColor: '#fff',

                // Secondary
                secondaryBgColor: '#fff',
                secondaryFgColor: '#DE3163',
                secondaryFgColorLight: '#fff',

                // splash screen
                splashScreenBgColor: '#fff;',

                // toolbar-system
                toolbarSystemFgColor: '#fff',
                toolbarSystemBgColor: '#DE3163',
            }
        };
    }

    setSkinGreen(): void {
        this.setTheme(this.getSkinGreen());
    }

    getSkinGreen(): ThemeConfigState {
        return {
            ...this.state,
            uuid: 'green',
            colors: {
                // Primary
                primaryBgColor: '#1e453e',
                primaryBgColorDark: '#306844',
                primaryBgColorLight: '#2c4c3b',
                primaryFgColor: '#fff',

                // Secondary
                secondaryBgColor: '#fff',
                secondaryFgColor: '#455b55',
                secondaryFgColorLight: '#fff',

                // splash screen
                splashScreenBgColor: '#040a06;',

                // toolbar-system
                toolbarSystemFgColor: '#040a06',
                toolbarSystemBgColor: '#97b3a1',
            }
        };
    }

    configTheme(theme): Observable<any> {
        return this._http
            .put<ItemResponse>(`${environment.URL_API}/projects/x/theme`, {theme: theme})
            .pipe(
                map(res => res.data.theme),
                tap(configTheme => this.setTheme(configTheme))
            );
    }

    requestSessionConfigTheme(code: string): void {
        this._http
            .post<ItemResponse>(`${environment.URL_API}/mod/participants/sessions/config/theme`, {code})
            .pipe(map(res => res.data.theme))
            .subscribe(theme => this.setTheme(theme));
    }

    setTheme(theme: ThemeConfigState, forceDispatch = false): void {
        const uuid = forceDispatch ? Math.random().toString() : theme.uuid;

        this.setState({
            ...this.state,
            uuid: uuid,
            colors: Object.assign({}, this.state.colors, theme.colors),
            messages: Object.assign({}, this.state.messages, theme.messages),
            logos: Object.assign({}, this.state.logos, theme.logos),
        });
    }
}
