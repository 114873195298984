export var ActionPanelEnum;
(function (ActionPanelEnum) {
    ActionPanelEnum["setSlide"] = "SET_SLIDE";
    // showSlide = 'SHOW_SLIDE',
    ActionPanelEnum["startSession"] = "START_SESSION";
    ActionPanelEnum["closeSession"] = "CLOSE_SESSION";
    ActionPanelEnum["showGraph"] = "SHOW_GRAPH";
    ActionPanelEnum["hideGraph"] = "HIDE_GRAPH";
    ActionPanelEnum["changeLabelGraph"] = "CHANGE_LABEL_GRAPH";
    ActionPanelEnum["questStart"] = "QUEST_START";
    ActionPanelEnum["questStop"] = "QUEST_STOP";
    ActionPanelEnum["questRestart"] = "QUEST_RESTART";
    ActionPanelEnum["questReset"] = "QUEST_RESET";
    ActionPanelEnum["showRight"] = "SHOW_RIGHT";
    ActionPanelEnum["hideRight"] = "HIDE_RIGHT";
    ActionPanelEnum["changeSessionId"] = "CHANGE_SESSION_ID";
    ActionPanelEnum["resetCacheAssets"] = "RESET_CACHE_ASSETS";
})(ActionPanelEnum || (ActionPanelEnum = {}));
var SessionPanel = /** @class */ (function () {
    function SessionPanel(event) {
        this.event = event;
    }
    Object.defineProperty(SessionPanel.prototype, "action", {
        get: function () {
            return this.event.payload.action;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionPanel.prototype, "data", {
        get: function () {
            return this.event.payload;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionPanel.prototype, "info", {
        get: function () {
            return this.event.payload.data;
        },
        enumerable: true,
        configurable: true
    });
    return SessionPanel;
}());
export { SessionPanel };
var GraphChangedData = /** @class */ (function () {
    function GraphChangedData(event) {
        this.event = event;
    }
    Object.defineProperty(GraphChangedData.prototype, "data", {
        get: function () {
            return this.event.payload.data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GraphChangedData.prototype, "results", {
        get: function () {
            return this.data.data;
        },
        enumerable: true,
        configurable: true
    });
    GraphChangedData.prototype.graphId = function () {
        return this.event.payload.data.graphId;
    };
    return GraphChangedData;
}());
export { GraphChangedData };
//// VOTE PARTICIPANT
var NewVoteData = /** @class */ (function () {
    function NewVoteData(event) {
        this.event = event;
    }
    Object.defineProperty(NewVoteData.prototype, "info", {
        get: function () {
            return this.event.payload;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewVoteData.prototype, "participant", {
        get: function () {
            return this.info.participant;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewVoteData.prototype, "vote", {
        get: function () {
            return this.info.vote;
        },
        enumerable: true,
        configurable: true
    });
    return NewVoteData;
}());
export { NewVoteData };
