import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/messaging";
var MessagingService = /** @class */ (function () {
    function MessagingService(angularFireMessaging) {
        this.angularFireMessaging = angularFireMessaging;
        this.currentMessage = new BehaviorSubject(null);
        this._bind();
    }
    MessagingService.prototype._bind = function () {
        if (!environment.production) {
            return;
        }
        this.angularFireMessaging.messaging
            .subscribe(function (_messaging) {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            // // Customize notification here
            // const notificationTitle = 'Background Message Title';
            // const notificationOptions = {
            //     body: 'Background Message body.',
            //     icon: '/firebase-logo.png'
            // };
            //
            // return _messaging.registration.showNotification(notificationTitle, notificationOptions);
        });
    };
    MessagingService.prototype.requestToken = function () {
        if (!environment.production) {
            return of(null);
        }
        return this.angularFireMessaging.requestToken;
    };
    /**
     * hook method when new notification received in foreground
     */
    MessagingService.prototype.receiveMessage = function () {
        var _this = this;
        if (!environment.production) {
            return;
        }
        this.angularFireMessaging.messages.subscribe(function (payload) {
            _this.currentMessage.next(payload);
        });
    };
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.AngularFireMessaging)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
