export class ThemeConfigColors {
    // Primary
    primaryBgColor: string;
    primaryBgColorDark: string;
    primaryBgColorLight: string;
    primaryFgColor: string;

    // Secondary
    secondaryBgColor: string;
    secondaryFgColor: string;
    secondaryFgColorLight: string;


    // splash screen
    splashScreenBgColor: string;

    // toolbar-system
    toolbarSystemFgColor: string;
    toolbarSystemBgColor: string;
}

export class ThemeConfigMessages {
    welcomeTitle: string;
}

export class ThemeConfigLogos {
    primaryLogo: string;
    secondaryLogo: string;
    secondaryBgImage: string;
}

export class ThemeConfigState {
    uuid: string;
    colors: ThemeConfigColors;
    messages: ThemeConfigMessages;
    logos: ThemeConfigLogos;
}

export const themeConfigDefault: ThemeConfigState = {
    uuid: 'default',
    colors: {
        primaryBgColor: '#0E2A3B',
        primaryBgColorDark: '#2D323E',
        primaryBgColorLight: '#0E2A3B',
        primaryFgColor: '#fff',

        secondaryBgColor: '#03a9f4',
        secondaryFgColor: '#fff',
        secondaryFgColorLight: '#fff',


        // splash screen
        splashScreenBgColor: '#3C4252',

        // toolbar-system
        toolbarSystemFgColor: '#fff',
        toolbarSystemBgColor: '#3c4252',
    },
    messages: {
        welcomeTitle: 'Bem vindos!',
    },
    logos: {
        primaryLogo: 'assets/images/logos/logo-sm.png',
        secondaryLogo: 'assets/images/logos/logo-yellow.png',
        secondaryBgImage: 'assets/images/backgrounds/dark-material-bg.jpg',
    }
};
