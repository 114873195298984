import { W3StorageService } from '@rapi/w3/apps/storage';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { environment } from '../../../environments/environment';
var SipSocketService = /** @class */ (function () {
    function SipSocketService(storage) {
        this.storage = storage;
        this._channels = [];
    }
    Object.defineProperty(SipSocketService.prototype, "echo", {
        get: function () {
            return this._echo;
        },
        enumerable: true,
        configurable: true
    });
    SipSocketService.prototype.connect = function () {
        if (this._echo) {
            return this;
        }
        var token = this.storage.get('access_token');
        var APP_KEY = environment.SOCKET_APP_KEY;
        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = environment.DEBUG;
        var pusher = new Pusher(APP_KEY, {
            cluster: 'none',
            wsHost: environment.SOCKET_HOST,
            forceTLS: environment.SOCKET_FORCE_TLS,
            wsPort: environment.SOCKET_PORT,
            enableStats: false,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            channelAuthorization: {
                transport: 'ajax',
                headers: {
                    'Authorization': "Bearer " + token
                },
                endpoint: environment.SOCKET_URL_AUTH
            },
        });
        this._echo = new Echo({
            broadcaster: 'pusher',
            encrypted: true,
            client: pusher,
        });
        return this;
    };
    SipSocketService.prototype.joinChannel = function (channel) {
        this._channels.push(channel);
        return this._echo.join(channel);
    };
    SipSocketService.prototype.createPrivateChannel = function (channel) {
        this._channels.push(channel);
        return this._echo.private(channel);
    };
    SipSocketService.prototype.createPublicChannel = function (channel) {
        this._channels.push(channel);
        return this._echo.channel(channel);
    };
    SipSocketService.prototype.leave = function (ch) {
        if (this._echo) {
            this._echo.leave(ch.name);
        }
    };
    SipSocketService.prototype.reset = function () {
        var _this = this;
        if (this._echo) {
            this._channels.map(function (ch) { return _this._echo.leave(ch); });
            this._echo = null;
            this._channels = [];
        }
    };
    return SipSocketService;
}());
export { SipSocketService };
