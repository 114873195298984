// REF: https://jurebajt.com/state-management-in-angular-with-observable-store-services/
import { BehaviorSubject } from 'rxjs';
var SimpleStore = /** @class */ (function () {
    function SimpleStore(initialState) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
    }
    Object.defineProperty(SimpleStore.prototype, "state", {
        get: function () {
            return this._state$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    SimpleStore.prototype.setPartialState = function (data) {
        var newState = Object.assign(this.state, data);
        this.setState(newState);
    };
    SimpleStore.prototype.setState = function (nextState) {
        this._state$.next(nextState);
    };
    SimpleStore.prototype.debug = function (name) {
        // this.state$.subscribe(s => console.log(`SimpleStore<${name}>.state$`, s));
    };
    return SimpleStore;
}());
export { SimpleStore };
// EXAMPLE
//
// class MyState extends SimpleStore<string> {
//     constructor() {
//         super('');
//     }
// }
//
// const state = new MyState;
// state.setState('aterando stado');
// const result = state.state; // sainda aterando stado
// state.state$.subscribe(s => console.log(s));
