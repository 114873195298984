export enum PostFromEnum {
    AUTH = 'auth',
    GUEST = 'guest',
    UNLOAD = 'unload'
}

export class PostState {
    posts: Post[];
    currentSlug: string;
    from: PostFromEnum;
}

export interface Post {
    id?: number;
    title: string;
    slug?: string;
    content: string;
    status: boolean;
    thumb?: string;
    _thumb?: string;
    _files?: FilePages[];
    visibility: string;
    metas?: any;
    created_at?: string;
    updated_at?: string;
    deleted_at?: any;
}

export interface FilePages {
    mime: string;
    name: string;
    path: string;
    _pathSrc?: string;
}
