import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';

import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseSidebarModule} from '@fuse/components';

import {W3_MESSAGE_RESPONSE, W3ConfigToast} from '@rapi/w3';
import {W3Config} from '@rapi/w3/w3.config';
import {W3Module} from '@rapi/w3/w3.module';
import {W3ApiToastInterceptor} from '@rapi/w3/interceptor/api-toast.Interceptor';
import {W3_AUTH_SERVICE, W3_PROTECTED_FALLBACK_PAGE_URI, W3_PUBLIC_FALLBACK_PAGE_URI, W3AuthModule} from '@rapi/w3/apps/auth';

import {WebSocketModule} from '@sip/common/web-socket/web-socket.module';
import {SIPMessagesLv56ResponseService} from '@sip/common/services/messages-lv56-response.service';

import {LayoutModule} from 'app/layout/layout.module';
import {AppRoutingModule} from './app.routing.module';

import {AuthService} from './shared/services/auth.service';
import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {environment} from '../environments/environment';

const w3Config: W3Config = {
    storage: {
        prefix: 'sip_pwa',
        drive: 'STORAGE'
    }
};

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        ReactiveFormsModule,

        TranslateModule.forRoot(),
        ToastrModule.forRoot(W3ConfigToast),

        W3Module.forRoot(w3Config),
        W3AuthModule,

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,

        WebSocketModule,

        // App modules
        LayoutModule,
        // AppStoreModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ],
    providers: [
        // AUTH
        {provide: W3_AUTH_SERVICE, useClass: AuthService},
        {provide: W3_PROTECTED_FALLBACK_PAGE_URI, useValue: '/slide'},
        {provide: W3_PUBLIC_FALLBACK_PAGE_URI, useValue: '/'},

        // TOAST
        {provide: W3_MESSAGE_RESPONSE, useClass: SIPMessagesLv56ResponseService},
        {provide: HTTP_INTERCEPTORS, useClass: W3ApiToastInterceptor, multi: true}
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
