<div id="main" >

    <div id="container-1" class="container">

        <!-- NAVBAR: Left -->
        <ng-container >
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">


            <div id="container-3" class="container" fusePerfectScrollbar
                 [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- TOOLBAR: Below static -->
                <!--<ng-container>-->
                    <!--<ng-container *ngTemplateOutlet="toolbar"></ng-container>-->
                <!--</ng-container>-->
                <!-- / TOOLBAR: Below static -->

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->



            </div>



        </div>



    </div>



</div>


<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
             [ngClass]="[fuseConfig.layout.toolbar.position, fuseConfig.layout.toolbar.background]"></toolbar>
</ng-template>
<!-- / TOOLBAR -->


<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar"
                  [folded]="fuseConfig.layout.navbar.folded"
                  lockedOpen="gt-md"
                  *ngIf="!fuseConfig.layout.navbar.hidden">

        <navbar class="left-navbar" [ngClass]="fuseConfig.layout.navbar.background" layout="vertical"></navbar>

    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->