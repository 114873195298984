import {NgModule} from '@angular/core';
import {MatDialogModule, MatToolbarModule} from '@angular/material';

import {FuseNavigationModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {SipCommonConfigModule} from '@sip/common/config/config.module';

import {SharedModule} from '../../../shared/shared.module';
import {NavbarComponent} from 'app/layout/components/navbar/navbar.component';
import {ShowMsgComponent} from './show-msg/show-msg.component';


@NgModule({
    declarations: [
        NavbarComponent,
        ShowMsgComponent
    ],
    imports: [
        SharedModule,
        MatDialogModule,
        MatToolbarModule,
        FuseNavigationModule,
        FuseSharedModule,

        SipCommonConfigModule,
    ],
    exports: [
        NavbarComponent,
        ShowMsgComponent
    ],
    providers: [],
    entryComponents: [
        ShowMsgComponent
    ]
})
export class NavbarModule {
}
