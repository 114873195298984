
<div class="header mat-accent-bg p-16 p-sm-24" fxLayout="column"  fxLayoutAlign="space-between none">
    <div fxLayout="row" fxLayoutAlign="start center">
        <sip-shared-btn-toggle-sidebar></sip-shared-btn-toggle-sidebar>

        <h1 [@animate]="{value:'*',params:{delay:'100ms', y:'25px'}}">
            {{label}}
        </h1>
    </div>


    <div class="hero-text">

        <mat-icon class="hero-icon">{{icon}}</mat-icon>
        <ng-content></ng-content>

    </div>

</div>