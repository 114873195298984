import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Response20x} from '@rapi/w3';

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {ParticipantStatusEnum} from '@sip/common/models';

import {environment} from '../../../environments/environment';
import {ParticipantModel} from '../models/partipant.model';

@Injectable({
    providedIn: 'root'
})
export class ParticipantService {

    constructor(private http: HttpClient) {
    }

    me(include?: string): Observable<ParticipantModel> {
        return this.http
            .get(`${environment.URL_API}/mod/participants/me`, {params: {include}})
            .pipe(map((r: Response20x) => r.data));
    }

    update(data: any): Observable<ParticipantModel> {
        return this.http
            .put<Response20x>(`${environment.URL_API}/mod/participants/me`, data)
            .pipe(map(r => r.data));
    }

    updateStatus(status: ParticipantStatusEnum): Observable<any> {
        return this.http
            .put<Response20x>(`${environment.URL_API}/mod/participants/me/status`, {status})
            .pipe(map(r => r.data));
    }
}
