import {PanelConfigModel} from './index';

export enum ActionPanelEnum {
    setSlide = 'SET_SLIDE',
    // showSlide = 'SHOW_SLIDE',
    startSession = 'START_SESSION',
    closeSession = 'CLOSE_SESSION',
    showGraph = 'SHOW_GRAPH',
    hideGraph = 'HIDE_GRAPH',
    changeLabelGraph = 'CHANGE_LABEL_GRAPH',
    questStart = 'QUEST_START',
    questStop = 'QUEST_STOP',
    questRestart = 'QUEST_RESTART',
    questReset = 'QUEST_RESET',
    showRight = 'SHOW_RIGHT',
    hideRight = 'HIDE_RIGHT',
    changeSessionId = 'CHANGE_SESSION_ID',
    resetCacheAssets = 'RESET_CACHE_ASSETS',
}

export interface SessionPanelEvent {

    payload: {
        action: ActionPanelEnum;
        session_id: number,
        data: any
    };

}

export class SessionPanel {

    constructor(public event: SessionPanelEvent) {
    }

    get action(): ActionPanelEnum {
        return this.event.payload.action;
    }

    get data(): any {
        return this.event.payload;
    }

    get info(): PanelConfigModel {
        return this.event.payload.data;
    }

}

// GRAPH
export interface GraphChangedGraphModel {
    id: number;
    color: string;
    weight: string;
}

export interface GraphChangedDataModel {
    right: boolean;
    label: string;
    value: number;
    weight: number;
    total: number;
    dataLabel: string;
}

export interface GraphChangedModel {
    graphId: number;
    questionId: number;
    label?: any;
    count: number;
    total: number;
    weight: number;
    participants: number;
    graph: GraphChangedGraphModel;
    data: GraphChangedDataModel[];
    extra?: any;
}

export class GraphChangedData {

    constructor(public event: SessionPanelEvent) {
    }

    get data(): GraphChangedModel {
        return this.event.payload.data;
    }

    get results(): GraphChangedDataModel[] {
        return this.data.data;
    }

    graphId(): number {
        return this.event.payload.data.graphId;
    }
}


//// VOTE PARTICIPANT
export class NewVoteData {

    constructor(public event: { payload: any }) {
    }

    get info(): NewVoteEvent {
        return this.event.payload;
    }

    get participant(): NewVoteParticipantModel {
        return this.info.participant;
    }

    get vote(): NewVoteVoteModel {
        return this.info.vote;
    }
}

export interface NewVoteParticipantModel {
    id: number;
    type: string;
    name: string;
    personalKey: string;
    weight: number;
}

export interface NewVoteVoteModel {
    start_vote: number;
    end_vote: number;
    time_elapsed: number;
    ignored_vote: boolean;
    been_corrected: boolean;
    right: boolean;
    score: number;
    chosen_response: string[];
    text_response?: any;
    response?: string;
    responseLegend?: string;
}

export interface NewVoteEvent {
    action?: any;
    session_id: number;
    participant: NewVoteParticipantModel;
    vote: NewVoteVoteModel;
}