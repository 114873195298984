/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/common";
import * as i10 from "./search-bar.component";
import * as i11 from "../../services/config.service";
var styles_FuseSearchBarComponent = [i0.styles];
var RenderType_FuseSearchBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseSearchBarComponent, data: {} });
export { RenderType_FuseSearchBarComponent as RenderType_FuseSearchBarComponent };
function View_FuseSearchBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["aria-label", "Expand Search Bar"], ["class", "fuse-search-bar-expander"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "s-24 secondary-text mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["search"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_2); }); }
export function View_FuseSearchBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "fuse-search-bar"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "expanded": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "fuse-search-bar-content"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [["for", "fuse-search-bar-input"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseSearchBarComponent_1)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "input", [["class", "ml-24"], ["fxFlex", ""], ["id", "fuse-search-bar-input"], ["placeholder", "Search"], ["type", "text"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.search($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["aria-label", "Collapse Search Bar"], ["class", "fuse-search-bar-collapser"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "mat-icon", [["class", "s-24 secondary-text mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(12, 638976, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fuse-search-bar"; var currVal_1 = _ck(_v, 2, 0, !_co.collapsed); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "fuse-search-bar-content"; var currVal_3 = _co.fuseConfig.layout.toolbar.background; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.collapsed; _ck(_v, 7, 0, currVal_4); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 12).inline; _ck(_v, 11, 0, currVal_7); }); }
export function View_FuseSearchBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-search-bar", [], null, null, null, View_FuseSearchBarComponent_0, RenderType_FuseSearchBarComponent)), i1.ɵdid(1, 245760, null, 0, i10.FuseSearchBarComponent, [i11.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FuseSearchBarComponentNgFactory = i1.ɵccf("fuse-search-bar", i10.FuseSearchBarComponent, View_FuseSearchBarComponent_Host_0, {}, { input: "input" }, []);
export { FuseSearchBarComponentNgFactory as FuseSearchBarComponentNgFactory };
