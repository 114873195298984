<div class="dialog-content-wrapper dialog-form-project p-0" fusePerfectScrollbar>

    <mat-toolbar matDialogTitle class="mat-white-bg m-0" mat-dialog-title>
        <div matDialogTitle fxFlex fxLayout="row"  fxLayoutAlign="start center" style="height: 62px;">
            <mat-icon class="mr-8 green-500-fg">done_all</mat-icon>
            <span class="title dialog-title">Sucesso</span>

        </div>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 m-0"  >
    <div fxLayout="column" fxLayoutAlign="space-between none">
        <b>Seu voto foi computado com sucesso.</b>



        <small *ngIf="data.hash" class="secondary-text-matte cursor-text">Comprovante: {{data.hash}}</small>
    </div>

    </div>

    <div mat-dialog-actions class="m-0 px-8 pb-8" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.gt-sm="end center">


        <button mat-raised-button color="accent"
                (click)="dialogRef.close()"
                aria-label="OK">
            <mat-icon>close</mat-icon>
            OK
        </button>

    </div>
</div>
