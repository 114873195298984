import { OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { ThemeConfigService } from '@sip/common/config/theme-config.service';
import { MessagingService } from '@sip/common/services/messaging.service';
import { SessionPrefetchService } from '@sip/common/services/session-prefetch.service';
import { ActionPanelEnum, SessionPanel } from '@sip/common/models';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { PostService } from '../../../shared/services/post.service';
import { SessionConfigService } from '../../../shared/services/session-config.service';
import { AlertAllParticipants, ProjectChangedConfig } from '../../../shared/events';
import { ShowMsgComponent } from './show-msg/show-msg.component';
import { environment } from '../../../../environments/environment';
import { navigationAuth, navigationGuest } from '../../../navigation';
import { ParticipantService } from '../../../shared/services/participant.service';
import { ChatStoreService } from '../../../shared/services/chat.store.service';
import { ClearDataEvent } from '../../../shared/services/events';
import { VoteService } from '../../../shared/services/vote.service';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(config, _fuseNavigationService, _fuseSidebarService, _router, _messageService, _me, _postService, _sessionPrefetchService, _chatStore, _sipThemeStore, _votService, dialog) {
        this.config = config;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._router = _router;
        this._messageService = _messageService;
        this._me = _me;
        this._postService = _postService;
        this._sessionPrefetchService = _sessionPrefetchService;
        this._chatStore = _chatStore;
        this._sipThemeStore = _sipThemeStore;
        this._votService = _votService;
        this.dialog = dialog;
        // Set the defaults
        this.layout = 'vertical';
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.versionSystem = environment.APP_VERSION;
    }
    Object.defineProperty(NavbarComponent.prototype, "directive", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        // Directive
        set: function (theDirective) {
            var _this = this;
            if (!theDirective) {
                return;
            }
            this._fusePerfectScrollbar = theDirective;
            this._fuseNavigationService.onItemCollapseToggled
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(function () {
                _this.fusePerfectScrollbarUpdateTimeout = setTimeout(function () {
                    _this._fusePerfectScrollbar.update();
                }, 310);
            });
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.config.session$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (session) {
            _this.session = session;
            _this.checkTypeView();
            _this.checkPages();
            if (session) {
                _this._sipThemeStore.requestSessionConfigTheme(session.code);
                _this.checkAssetForFetch(session.code);
            }
        });
        this.config.participant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (participant) {
            _this.participant = participant;
            _this.checkPages();
            _this.setNavigation();
            _this.registerTokenFromUser();
        });
        // LISTENER NEW MSG ALERT
        this.config.broadCast$
            .pipe(takeUntil(this._unsubscribeAll), filter(function (e) { return e instanceof AlertAllParticipants; }))
            .subscribe(function (e) {
            // mytodo custon alert -> alertMessage
            if (!e || !e.message) {
                return;
            }
            _this.showAlert(e.message);
        });
        // LISTENER NEW THEME
        this._sipThemeStore.bind();
        this.config.broadCast$
            .pipe(takeUntil(this._unsubscribeAll), filter(function (e) { return e instanceof ProjectChangedConfig; }))
            .subscribe(function (e) {
            _this._sipThemeStore.setTheme(e.config);
        });
        // LISTENER ON SESSION PANEL
        this.config.broadCast$
            .pipe(takeUntil(this._unsubscribeAll), filter(function (e) { return e instanceof SessionPanel; }))
            .subscribe(function (e) {
            switch (e.action) {
                case ActionPanelEnum.closeSession:
                    _this._router.navigate(['/sessao/fim']);
                    break;
                case ActionPanelEnum.resetCacheAssets:
                    _this.checkAssetForFetch(_this.session.code);
                    break;
            }
        });
        // LISTENER ON SESSION PANEL
        this.config.broadCast$
            .pipe(takeUntil(this._unsubscribeAll), filter(function (e) { return e instanceof ClearDataEvent; }))
            .subscribe(function () {
            _this._votService.clearAllVotes();
        });
        this._router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this._fuseSidebarService.getSidebar('navbar')) {
                _this._fuseSidebarService.getSidebar('navbar').close();
            }
        });
        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(filter(function (value) { return value !== null; }))
            .subscribe(function () {
            _this.navigation = _this._fuseNavigationService.getCurrentNavigation();
            _this.checkTypeView();
        });
        // LISTENER POSTS AND ADD NAV
        this._postService.state$
            .pipe(map(function (state) { return state.posts; }), distinctUntilChanged(), takeUntil(this._unsubscribeAll)).subscribe(function (_) { return _this.addNavigationsByPostService(); });
        this._chatStore.onChangeUnread()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (n) {
            var nav = _this._fuseNavigationService.getNavigationItem('perguntar');
            if (nav) {
                nav.badge = !n ? null : {
                    title: n.toString(),
                    bg: '#F44336',
                    fg: '#FFFFFF'
                };
            }
        });
    };
    /**
     * On destroy
     */
    NavbarComponent.prototype.ngOnDestroy = function () {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    NavbarComponent.prototype.alertMessage = function (msg) {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.width = '300px';
        dialogConfig.data = msg;
        this.dialog.open(ShowMsgComponent, dialogConfig)
            .afterClosed().subscribe(function (result) {
        });
    };
    NavbarComponent.prototype.goToHome = function () {
        this._router.navigateByUrl('session');
        // this._router.navigate([`${this.config.code}/`]);
    };
    /**
     * Toggle sidebar opened status
     */
    NavbarComponent.prototype.toggleSidebarOpened = function () {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    };
    /**
     * Toggle sidebar folded status
     */
    NavbarComponent.prototype.toggleSidebarFolded = function () {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    };
    NavbarComponent.prototype.setNavigation = function () {
        if (this.participant) {
            this.navigation = navigationAuth.slice();
            this._fuseNavigationService.setCurrentNavigation('authenticated');
        }
        else {
            this.navigation = navigationGuest.slice();
            this._fuseNavigationService.setCurrentNavigation('main');
        }
    };
    NavbarComponent.prototype.showAlert = function (message) {
        var _this = this;
        this.alertMessage(message);
        // Verifica se o browser suporta notificações
        if (!('Notification' in window)) {
            console.log('Este browser não suporta notificações de Desktop');
            return;
        }
        if (environment.production === false) {
            console.log('Rodando em modo dev, por isso não será exibido alert');
            return;
        }
        Notification.requestPermission(function (result) {
            if (result === 'granted') {
                _this.spawnNotification(message);
            }
        });
    };
    NavbarComponent.prototype.checkTypeView = function () {
        if (!this.session) {
            return;
        }
        var calendarNavItem = this._fuseNavigationService.getNavigationItem('views');
        var passwordNavItem = this._fuseNavigationService.getNavigationItem('password');
        if (calendarNavItem) {
            calendarNavItem.hidden = this.session.view !== 'all';
        }
        if (passwordNavItem) {
            passwordNavItem.hidden = this.session.identify === 'anonymous' || this.session.identify === 'public';
        }
    };
    NavbarComponent.prototype.spawnNotification = function (message) {
        // https://web-push-book.gauntface.com/chapter-05/02-display-a-notification/#icon
        // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification
        navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification('SIP - avisos:', {
                body: message,
                icon: '/app/assets/images/logos/logo-sm-black.png',
                tag: 'sip'
            });
        });
    };
    NavbarComponent.prototype.registerTokenFromUser = function () {
        var _this = this;
        if (!this.participant) {
            return;
        }
        this._messageService.requestToken()
            .pipe(takeUntil(this._unsubscribeAll), filter(function (token) { return !!token; }), mergeMap(function (token) { return _this._me.update({ 'fcmToken': token }); }))
            .subscribe(function (r) { return console.log('Token UPDATED', r); });
    };
    NavbarComponent.prototype.checkPages = function () {
        if (!this.session) {
            return;
        }
        if (this.participant) {
            this._postService.loadPagesAuth(this.session.code);
        }
        else {
            this._postService.loadPagesGuest(this.session.code);
        }
    };
    NavbarComponent.prototype.checkAssetForFetch = function (code) {
        this._sessionPrefetchService.startPrefetchAssets(code, 'participant');
    };
    NavbarComponent.prototype.addNavigationsByPostService = function () {
        var _this = this;
        var navs = this._fuseNavigationService.getCurrentNavigation();
        this.setNavigation();
        // console.log('addNavigationsByPostService', this._postService.getNavigations(`/${this.session.code}`));
        // navs.forEach(m => {
        //     if (m.id.includes('post-')) {
        //         console.log('REMOVEEE', m);
        //         this._fuseNavigationService.removeNavigationItem(m.id);
        //     }
        // });
        var urlInit = this.session ? "/" + this.session.code : '';
        this._postService.getNavigations(urlInit)
            .map(function (p) {
            if (!navs.find(function (n) { return n.id === p.id; })) {
                _this._fuseNavigationService.addNavigationItem(p, 'end');
            }
        });
    };
    return NavbarComponent;
}());
export { NavbarComponent };
