import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {W3ProtectedGuard, W3PublicGuard} from '@rapi/w3/apps/auth';

import {CodeSessionByUrlGuard} from './shared/guards/code-session-by-url.guard';
import {ParticipantSessionGuard} from './shared/guards/participant-session.guard';


const routes: Routes = [
    {
        path: '',
        loadChildren: './main/home/home.module#HomeModule',
        canActivate: [W3PublicGuard]
    },
    // {
    //     path: 'demo-g',
    //     component: DemoGraphComponent, // mytodo remove
    // },
    {
        path: 'erro',
        loadChildren: './main/error/erro.module#ErroModule'
    },
    {
        path: 'slide',
        loadChildren: './main/slide/slide.module#SlideModule',
        canActivate: [W3ProtectedGuard, ParticipantSessionGuard],
        canActivateChild: [W3ProtectedGuard, ParticipantSessionGuard]
    },
    {
        path: 'keypad',
        loadChildren: './main/keypad/keypad.module#KeypadModule',
        canActivate: [W3ProtectedGuard, ParticipantSessionGuard],
        canActivateChild: [W3ProtectedGuard, ParticipantSessionGuard]
    },
    {
        path: 'sessao',
        loadChildren: './main/sessao/sessao.module#SessaoModule',
        canActivate: [W3ProtectedGuard, ParticipantSessionGuard],
        canActivateChild: [W3ProtectedGuard, ParticipantSessionGuard]
    },
    {
        path: 'auth', // para aproveitar rota de logout
        loadChildren: './main/auth/auth.module#AuthModule'
    },
    {
        path: ':codigo',
        loadChildren: './main/auth/auth.module#AuthModule',
        canActivateChild: [CodeSessionByUrlGuard]
    },
    {
        path: '**',
        redirectTo: '/erro' // Gera msg 404
    }
];

@NgModule({
    imports: [
        // SipCommonModule, // mytodo remove
        RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}