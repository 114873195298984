import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Response20x} from '@rapi/w3';

import {environment} from '../../../environments/environment';
import {IRepresentativeResponse, IRepresentativeVoteDto, IRepresentativeVoteResponse, UnitModel} from '../models/partipant.model';

@Injectable()
export class RepresentativeService {

    public slideID$: Observable<number | null>;
    private slideIDSubject = new BehaviorSubject<number | null>(null);

    constructor(private http: HttpClient) {
        this.slideID$ = this.slideIDSubject.asObservable();
    }

    getSlideID(): number | null {
        return this.slideIDSubject.getValue();
    }

    setSlideID(id: number | null): void {
        this.slideIDSubject.next(id);
    }

    getUnities(): Observable<{ quantity: number, weight_total: number, unities: UnitModel[] }> {
        return this.http
            .get<Response20x>(`${environment.URL_API}/mod/participants/representative/unities`)
            .pipe(
                map((r) => r.data),
                map((data) => {
                    data.unities = data.unities.map(unit => this.presentUnit(unit));
                    return data;
                })
            );
    }

    getVotes(slideId: number | string): Observable<IRepresentativeResponse> {
        return this.http
            .get<Response20x>(`${environment.URL_API}/mod/participants/representative/votes/${slideId}`)
            .pipe(
                map((r) => r.data),
                map((data: IRepresentativeResponse) => {
                    data.unities = data.unities.map(sub => {
                        sub.unit = this.presentUnit(sub.unit);
                        return sub;
                    });
                    return data;
                })
            );
    }

    sendVotes(dto: IRepresentativeVoteDto): Observable<IRepresentativeVoteResponse> {
        return this.http
            .post<Response20x>(`${environment.URL_API}/mod/participants/representative/votes`, dto)
            .pipe(map((data: IRepresentativeVoteResponse) => {
                data.data.map(d => {
                    d.unit = this.presentUnit(d.unit);
                    return d;
                });

                return data;
            }));
    }

    private presentUnit(unit: UnitModel): UnitModel {
        unit.weight = isNaN(+unit.weight) ? 1 : +unit.weight;
        return unit;
    }

}
