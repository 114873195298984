// REF: https://jurebajt.com/state-management-in-angular-with-observable-store-services/
import {BehaviorSubject, Observable} from 'rxjs';

export abstract class SimpleStore<T> {
    state$: Observable<T>;
    private _state$: BehaviorSubject<T>;

    protected constructor(initialState: T) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
    }

    get state(): T {
        return this._state$.getValue();
    }

    setPartialState(data: any): void {
        const newState = Object.assign(this.state, data);
        this.setState(newState);
    }

    setState(nextState: T): void {
        this._state$.next(nextState);
    }

    protected debug(name): void {
        // this.state$.subscribe(s => console.log(`SimpleStore<${name}>.state$`, s));
    }
}

// EXAMPLE
//
// class MyState extends SimpleStore<string> {
//     constructor() {
//         super('');
//     }
// }
//
// const state = new MyState;
// state.setState('aterando stado');
// const result = state.state; // sainda aterando stado
// state.state$.subscribe(s => console.log(s));


