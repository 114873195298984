export enum ParticipantStatusEnum {
    ALL = 'ALL',
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    AWAY = 'AWAY',
    CONNECTED = 'CONNECTED'
}

export interface UserSocketModel {
    id?: number;
    type?: 'admin' | 'participant';
    name?: string;
    personalKey?: string;
    status?: ParticipantStatusEnum;
}
