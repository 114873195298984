import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'inicio',
        title: 'Início',
        type: 'item',
        icon: 'airplay',
        url: '/'
    }
];
