var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { W3MessagesBaseResponseService } from './messages-base-response.service';
/**
 * https://github.com/scttcper/ngx-toastr
 */
var W3MessagesLv56ResponseService = /** @class */ (function (_super) {
    __extends(W3MessagesLv56ResponseService, _super);
    function W3MessagesLv56ResponseService(_toast) {
        return _super.call(this, _toast) || this;
    }
    W3MessagesLv56ResponseService.prototype.respondNotFound = function (data) {
        if (data.status === 'error' && data.message) {
            this._toast.error(data.message);
        }
    };
    W3MessagesLv56ResponseService.prototype.respondValidation = function (data) {
        if (!data.error) {
            return;
        }
        var error = '';
        if (data.error.validation) {
            error = '<ul>';
            _.forEach(data.error.validation, function (msg) {
                error += '<li>' + msg + '</li>';
            });
            error += '</ul>';
        }
        this._toast.error(error, data.message);
    };
    /**
     * // Usuario não possui essa permissão CAM/ROLE
     * @param data
     */
    W3MessagesLv56ResponseService.prototype.respondPermissionRequired = function (data) {
        var msg = (data.error && data.message)
            ? data.message
            : 'Seu usuário não possui acesso!';
        this._toast.warning(msg);
    };
    W3MessagesLv56ResponseService.prototype.respondUnauthorized = function (data) {
        if (this.getCode(data) === 'UNAUTHORIZED') {
            return;
        }
        var msg = (data.error && data.message)
            ? data.message
            : 'Favor efetuar login!';
        this._toast.info(msg);
    };
    W3MessagesLv56ResponseService.prototype.respondError = function (data) {
        if (data.error && data.message) {
            this._toast.error(data.message);
        }
        else {
            this.respondInternalError(data);
        }
    };
    W3MessagesLv56ResponseService.prototype.getCode = function (data) {
        return data.error ? data.error.code : null;
    };
    return W3MessagesLv56ResponseService;
}(W3MessagesBaseResponseService));
export { W3MessagesLv56ResponseService };
