/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-1.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/toolbar/toolbar.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../../components/toolbar/toolbar.component";
import * as i5 from "../../../../@fuse/services/config.service";
import * as i6 from "../../../../@fuse/components/sidebar/sidebar.service";
import * as i7 from "@angular/router";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../@fuse/components/sidebar/sidebar.component.ngfactory";
import * as i10 from "../../../../@fuse/components/sidebar/sidebar.component";
import * as i11 from "@angular/animations";
import * as i12 from "../../../../@fuse/services/match-media.service";
import * as i13 from "@angular/flex-layout";
import * as i14 from "../../components/navbar/navbar.component.ngfactory";
import * as i15 from "../../components/navbar/navbar.component";
import * as i16 from "../../../shared/services/session-config.service";
import * as i17 from "../../../../@fuse/components/navigation/navigation.service";
import * as i18 from "../../../../@sip/common/services/messaging.service";
import * as i19 from "../../../shared/services/participant.service";
import * as i20 from "../../../shared/services/post.service";
import * as i21 from "../../../../@sip/common/services/session-prefetch.service";
import * as i22 from "../../../shared/services/chat.store.service";
import * as i23 from "../../../../@sip/common/config/theme-config.service";
import * as i24 from "../../../shared/services/vote.service";
import * as i25 from "@angular/material/dialog";
import * as i26 from "../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i27 from "@angular/cdk/platform";
import * as i28 from "../../components/content/content.component.ngfactory";
import * as i29 from "../../components/content/content.component";
import * as i30 from "./layout-1.component";
var styles_VerticalLayout1Component = [i0.styles];
var RenderType_VerticalLayout1Component = i1.ɵcrt({ encapsulation: 2, styles: styles_VerticalLayout1Component, data: {} });
export { RenderType_VerticalLayout1Component as RenderType_VerticalLayout1Component };
function View_VerticalLayout1Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_VerticalLayout1Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "toolbar", [], null, null, null, i2.View_ToolbarComponent_0, i2.RenderType_ToolbarComponent)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), i1.ɵdid(3, 245760, null, 0, i4.ToolbarComponent, [i5.FuseConfigService, i6.FuseSidebarService, i7.Router, i8.TranslateService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.fuseConfig.layout.toolbar.position, _co.fuseConfig.layout.toolbar.background); _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
function View_VerticalLayout1Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerticalLayout1Component_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.fuseConfig.layout.toolbar.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
function View_VerticalLayout1Component_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "fuse-sidebar", [["lockedOpen", "gt-md"], ["name", "navbar"]], [[2, "open", null], [2, "locked-open", null], [2, "unfolded", null], [2, "animations-enabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_FuseSidebarComponent_0, i9.RenderType_FuseSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i10.FuseSidebarComponent, [i11.AnimationBuilder, i1.ChangeDetectorRef, i1.ElementRef, i5.FuseConfigService, i12.FuseMatchMediaService, i6.FuseSidebarService, i13.ObservableMedia, i1.Renderer2], { name: [0, "name"], lockedOpen: [1, "lockedOpen"], folded: [2, "folded"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "navbar", [["class", "left-navbar"], ["layout", "vertical"]], null, null, null, i14.View_NavbarComponent_0, i14.RenderType_NavbarComponent)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(4, 245760, null, 0, i15.NavbarComponent, [i16.SessionConfigService, i17.FuseNavigationService, i6.FuseSidebarService, i7.Router, i18.MessagingService, i19.ParticipantService, i20.PostService, i21.SessionPrefetchService, i22.ChatStoreService, i23.ThemeConfigService, i24.VoteService, i25.MatDialog], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = "navbar"; var currVal_5 = "gt-md"; var currVal_6 = _co.fuseConfig.layout.navbar.folded; _ck(_v, 1, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = "left-navbar"; var currVal_8 = _co.fuseConfig.layout.navbar.background; _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_9 = "vertical"; _ck(_v, 4, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).opened; var currVal_1 = i1.ɵnov(_v, 1).isLockedOpen; var currVal_2 = i1.ɵnov(_v, 1).unfolded; var currVal_3 = i1.ɵnov(_v, 1)._animationsEnabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_VerticalLayout1Component_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerticalLayout1Component_5)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.fuseConfig.layout.navbar.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VerticalLayout1Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["id", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "container"], ["id", "container-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerticalLayout1Component_1)), i1.ɵdid(4, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "container"], ["id", "container-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "container"], ["fusePerfectScrollbar", ""], ["id", "container-3"]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 4341760, null, 0, i26.FusePerfectScrollbarDirective, [i1.ElementRef, i5.FuseConfigService, i27.Platform, i7.Router], { fusePerfectScrollbarOptions: [0, "fusePerfectScrollbarOptions"], enabled: [1, "enabled"] }, null), i1.ɵpod(8, { suppressScrollX: 0, updateOnRouteChange: 1 }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "content", [], null, null, null, i28.View_ContentComponent_0, i28.RenderType_ContentComponent)), i1.ɵdid(10, 49152, null, 0, i29.ContentComponent, [], null, null), (_l()(), i1.ɵand(0, [["toolbar", 2]], null, 0, null, View_VerticalLayout1Component_2)), (_l()(), i1.ɵand(0, [["leftNavbar", 2]], null, 0, null, View_VerticalLayout1Component_4))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 8, 0, true, true); var currVal_2 = ""; _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_VerticalLayout1Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vertical-layout-1", [], null, null, null, View_VerticalLayout1Component_0, RenderType_VerticalLayout1Component)), i1.ɵdid(1, 245760, null, 0, i30.VerticalLayout1Component, [i5.FuseConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerticalLayout1ComponentNgFactory = i1.ɵccf("vertical-layout-1", i30.VerticalLayout1Component, View_VerticalLayout1Component_Host_0, {}, {}, []);
export { VerticalLayout1ComponentNgFactory as VerticalLayout1ComponentNgFactory };
