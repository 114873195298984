import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ItemResponse} from '@rapi/w3';
import {PanelConfigModel, SessionModel, SlideModel} from '@sip/common/models';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

@Injectable()
export class SessionService {

    constructor(private http: HttpClient) {
    }

    search(code: string): Observable<SessionModel> {
        const include = 'event';

        return this.http
            .post(`${environment.URL_API}/mod/participants/sessions/search`, {code, include})
            .pipe(map((r: ItemResponse) => r.data));
    }

    getSlides(include?: string): Observable<SlideModel[]> {

        const option = {
            params: {
                include: include
            }
        };

        return this.http
            .get(`${environment.URL_API}/mod/participants/slides`, option)
            .pipe(map((r: ItemResponse) => r.data));
    }

    currentInfoPanel(): Observable<PanelConfigModel> {
        return this.http
            .get(`${environment.URL_API}/mod/participants/slides/current?include=config`)
            .pipe(map((r: ItemResponse) => r.data.config));
    }

    infoPanel(slideId): Observable<PanelConfigModel> {
        return this.http
            .get(`${environment.URL_API}/mod/participants/slides/${slideId}/info`)
            .pipe(map((r: ItemResponse) => r.data));
    }

    getOptions(question: number): Observable<{ option: string, text: string }[]> {
        return this.http
            .get(`${environment.URL_API}/mod/participants/questions/${question}/options`)
            .pipe(map((r: ItemResponse) => r.data));
    }

}
