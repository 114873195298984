import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatToolbarModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';
import {W3Module} from '@rapi/w3/w3.module';

import {SessionService} from './services/session.service';
import {RepresentativeService} from './services/representative.service';

import {SessaoHeaderComponent} from './components/sessao-header/sessao-header.component';
import {BtnToggleSidebarComponent} from './components/btn-toggle-sidebar/btn-toggle-sidebar.component';
import {VoteHashDialogComponent} from './components/vote-hash-dialog/vote-hash-dialog.component';
import { MimeTypePipe } from './mime-type.pipe';


@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        W3Module,

        // Material

        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule

    ],
    exports: [
        CommonModule,
        FuseSharedModule,
        W3Module,

        // Material

        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        SessaoHeaderComponent,
        BtnToggleSidebarComponent,

        MimeTypePipe
    ],
    declarations: [
        SessaoHeaderComponent,
        BtnToggleSidebarComponent,
        MimeTypePipe,
        VoteHashDialogComponent
    ],
    entryComponents: [
        SessaoHeaderComponent,
        BtnToggleSidebarComponent,
        VoteHashDialogComponent
    ],
    providers: [
        SessionService,
        RepresentativeService
    ]
})
export class SharedModule {
}
