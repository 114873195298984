var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { skipToast } from '@rapi/w3';
import { SimpleStore } from '@sip/common/services/simple-store.service';
import { SessionConfigService } from './session-config.service';
import { MessageRegistered } from '../events';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./session-config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/snack-bar";
var ChatStoreService = /** @class */ (function (_super) {
    __extends(ChatStoreService, _super);
    function ChatStoreService(_config, _http, _router, _snackBar) {
        var _this = _super.call(this, {
            lastMessageUnread: null,
            unread: 0,
            messages: []
        }) || this;
        _this._config = _config;
        _this._http = _http;
        _this._router = _router;
        _this._snackBar = _snackBar;
        _this.listener();
        return _this;
        // this.debug('ChatState');
    }
    ChatStoreService.prototype.listener = function () {
        var _this = this;
        this._load();
        this._config.broadCast$
            .pipe(filter(function (e) { return e instanceof MessageRegistered; }))
            .subscribe(function (e) { return _this._handleAction(e); });
    };
    ChatStoreService.prototype._load = function () {
        var _this = this;
        var options = {
            params: {
                take: '-1',
                sort: 'created_at'
            }
        };
        this._http.get(environment.URL_API + "/mod/participants/messages", options)
            .subscribe(function (response) {
            var messages = response.data.map(function (d) { return new MessageRegistered(d, 'new_message'); });
            _this._setMessages(messages);
        });
    };
    ChatStoreService.prototype._handleAction = function (e) {
        switch (e.action) {
            case 'new_message':
                this._setNewMsg(e);
                break;
            case 'as_deleted':
                this._setMessages(this.state.messages.filter(function (m) { return m.id !== e.id; }));
                break;
            case 'as_read':
                // no actions, but unload
                break;
            default:
                this._load();
                break;
        }
    };
    ChatStoreService.prototype._setNewMsg = function (newMsg) {
        var msgs = this.state.messages.concat([newMsg]);
        if (newMsg.isSpeaker) {
            this.setState(__assign({}, this.state, { lastMessageUnread: newMsg, messages: msgs, unread: this.state.unread + 1 }));
        }
        else {
            this.setState(__assign({}, this.state, { lastMessageUnread: null, messages: msgs, unread: 0 }));
        }
    };
    ChatStoreService.prototype._setMessages = function (messages) {
        this.setState(__assign({}, this.state, { messages: messages.slice() }));
    };
    ChatStoreService.prototype.loadInit = function () {
        this._load();
    };
    ChatStoreService.prototype.onChangeMessages = function () {
        return this.state$
            .pipe(map(function (s) { return s.messages; }), distinctUntilChanged());
    };
    ChatStoreService.prototype.onNewMessage = function () {
        return this.state$
            .pipe(map(function (s) { return s.lastMessageUnread; }), filter(function (lstMsg) { return !!lstMsg; }), distinctUntilChanged());
    };
    ChatStoreService.prototype.onChangeUnread = function () {
        return this.state$
            .pipe(map(function (s) { return s.unread; }), distinctUntilChanged());
    };
    ChatStoreService.prototype.sendMessage = function (data) {
        return this._http.post(environment.URL_API + "/mod/participants/messages", data, skipToast)
            .pipe(map(function (result) { return result.data; })
        // tap(m => this._setNewMsg(m))
        );
    };
    ChatStoreService.prototype.setRead = function () {
        this.setState(__assign({}, this.state, { unread: 0 }));
    };
    ChatStoreService.prototype.showSnackNewMessage = function () {
        var _this = this;
        var message = this.state.lastMessageUnread
            ? "Voc\u00EA recebeu uma nova resposta: " + this.state.lastMessageUnread.message
            : "Voc\u00EA recebeu uma nova resposta!";
        this._snackBar
            .open(message, 'Ver', {
            duration: 3000,
        })
            .onAction()
            .subscribe(function () { return _this._router.navigateByUrl('/sessao/perguntar'); });
    };
    ChatStoreService.ngInjectableDef = i0.defineInjectable({ factory: function ChatStoreService_Factory() { return new ChatStoreService(i0.inject(i1.SessionConfigService), i0.inject(i2.HttpClient), i0.inject(i3.Router), i0.inject(i4.MatSnackBar)); }, token: ChatStoreService, providedIn: "root" });
    return ChatStoreService;
}(SimpleStore));
export { ChatStoreService };
