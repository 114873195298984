import { ModuleWithProviders } from '@angular/core';
import { W3AuthService } from './apps/auth';
import { W3NotificationService } from './services/notifications.service';
import { getPtBrPaginatorIntl } from './components/w3-paginator/pt-br-paginator-intl';
import { W3StorageOption } from './apps/storage';
import { W3_CONFIG } from './w3.config';
var ɵ0 = getPtBrPaginatorIntl();
var W3Module = /** @class */ (function () {
    function W3Module() {
    }
    // constructor(@Optional() @SkipSelf() parentModule: W3Module) {
    //     if (parentModule) {
    //         throw new Error('W3Module is already loaded. Import it in the AppModule only!');
    //     }
    // }
    W3Module.forRoot = function (configs) {
        return {
            ngModule: W3Module,
            providers: [
                W3NotificationService,
                W3AuthService,
                { provide: W3_CONFIG, useValue: configs },
                { provide: W3StorageOption, useValue: configs.storage },
            ]
        };
    };
    return W3Module;
}());
export { W3Module };
export { ɵ0 };
