import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {W3MessagesLv56ResponseService} from '@rapi/w3/interceptor/messages-lv56-response.service';
import {environment} from '../../../environments/environment';

/**
 * https://github.com/scttcper/ngx-toastr
 */

@Injectable()
export class SIPMessagesLv56ResponseService extends W3MessagesLv56ResponseService {

    constructor(_toast: ToastrService) {
        super(_toast);
    }

    respondOk(data): void {

        if (!data.message) {
            return;
        }

        switch (data.status) {
            case 'success':
                if (this.isDev()) {
                    this._toast.success('DEV: ' + data.message);
                }
                break;
            case 'error':
                this._toast.error(data.message);
                break;
            default:
                this._toast.info(data.message);
        }

    }

    private isDev(): boolean {
        return environment.DEBUG;
    }

}
