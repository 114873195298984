import {Component, OnInit} from '@angular/core';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

@Component({
    selector: 'sip-shared-btn-toggle-sidebar',
    templateUrl: './btn-toggle-sidebar.component.html',
    styleUrls: ['./btn-toggle-sidebar.component.scss']
})
export class BtnToggleSidebarComponent implements OnInit {

    constructor(private _fuseSidebarService: FuseSidebarService) {
    }

    ngOnInit(): void {
    }

    toggleSidebarOpen(key): void {
        const side = this._fuseSidebarService.getSidebar(key);

        if (side) {
            side.toggleOpen();
        }
    }

}
