import {ThemeConfigState} from '../../../@sip/common/config/config.model';
import * as moment from 'moment';

export interface EventPayload {
    data?: any;
}

export class AlertAllParticipants implements EventPayload {

    constructor(public data: any) {

    }

    get message(): string {
        return this.data.message;
    }
}

export class ProjectChangedConfig implements EventPayload {

    constructor(public data: any) {
    }

    get config(): ThemeConfigState {
        return this.data.theme;
    }
}

export class MessageRegistered implements EventPayload {

    constructor(public data: any, public action: 'as_read' | 'new_message' | 'as_deleted' | 'as_restored') {
    }

    get id(): number {
        return +this.data.id;
    }

    get name(): string {
        return this.data._name;
    }

    get isParticipant(): boolean {
        return !this.isSpeaker;
    }

    get isSpeaker(): boolean {
        return this.data._from === 'speaker';
    }

    get time(): string {
        return  moment(this.data.updated_at).format('DD/MM/YY HH:mm') || '';
    }

    get message(): string {
        return this.data.text;
    }
}
