import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LogoManagerDirective} from './logo-manager.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LogoManagerDirective
    ],
    exports: [
        LogoManagerDirective
    ]
})
export class SipCommonConfigModule {
}
