var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { distinctUntilKeyChanged, map, tap } from 'rxjs/operators';
import { W3StorageService } from '@rapi/w3/apps/storage';
import { SimpleStore } from '../services/simple-store.service';
import { themeConfigDefault } from './config.model';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../@rapi/w3/apps/storage/storage.service";
var ThemeConfigService = /** @class */ (function (_super) {
    __extends(ThemeConfigService, _super);
    function ThemeConfigService(_http, _store) {
        var _this = _super.call(this, themeConfigDefault) || this;
        _this._http = _http;
        _this._store = _store;
        _this._binder = false;
        _this.debug('ThemeConfigState');
        return _this;
    }
    ThemeConfigService.prototype.bind = function () {
        var _this = this;
        if (this._binder) {
            throw new Error('Has started bind!');
        }
        this._binder = true;
        var themeFreeze = this._store.get('theme_freeze');
        if (themeFreeze) {
            this.setTheme(JSON.parse(themeFreeze));
        }
        // setTimeout(() => this.setSkinPink(), 2000);
        // setTimeout(() => this.setSkinGreen(), 4000);
        // setTimeout(() => this.setLogoTest(), 2000);
        this.selectOnChangeUiid()
            .subscribe(function (state) {
            _this.updateStyle();
            _this.updateBackgrounds();
            _this._store.set('theme_freeze', JSON.stringify(state));
        });
    };
    ThemeConfigService.prototype.selectOnChangeUiid = function () {
        return this.state$
            .pipe(distinctUntilKeyChanged('uuid'));
    };
    ThemeConfigService.prototype.getUiid = function () {
        return this.state.uuid;
    };
    ThemeConfigService.prototype.getColors = function () {
        return this.state.colors;
    };
    ThemeConfigService.prototype.getMessages = function () {
        return this.state.messages;
    };
    ThemeConfigService.prototype.getLogos = function () {
        return this.state.logos;
    };
    ThemeConfigService.prototype.updateStyle = function () {
        var colors = this.getColors();
        Object.keys(colors).forEach(function (property) {
            document.documentElement.style.setProperty("--" + property, colors[property]);
        });
    };
    ThemeConfigService.prototype.updateBackgrounds = function () {
        var logos = this.getLogos();
        Object.keys(logos).forEach(function (property) {
            document.documentElement.style.setProperty("--" + property, "url(" + logos[property]);
        });
    };
    ThemeConfigService.prototype.setLogoTest = function () {
        this.setState(__assign({}, this.state, { uuid: 'changed_log', logos: __assign({}, this.state.logos) }));
    };
    ThemeConfigService.prototype.setSkinPink = function () {
        this.setTheme(this.getSkinPink());
    };
    ThemeConfigService.prototype.getSkinPink = function () {
        return __assign({}, this.state, { uuid: 'pink', colors: {
                // Primary
                primaryBgColor: '#DF5286',
                primaryBgColorDark: '#DF5286',
                primaryBgColorLight: '#E0115F',
                primaryFgColor: '#fff',
                // Secondary
                secondaryBgColor: '#fff',
                secondaryFgColor: '#DE3163',
                secondaryFgColorLight: '#fff',
                // splash screen
                splashScreenBgColor: '#fff;',
                // toolbar-system
                toolbarSystemFgColor: '#fff',
                toolbarSystemBgColor: '#DE3163',
            } });
    };
    ThemeConfigService.prototype.setSkinGreen = function () {
        this.setTheme(this.getSkinGreen());
    };
    ThemeConfigService.prototype.getSkinGreen = function () {
        return __assign({}, this.state, { uuid: 'green', colors: {
                // Primary
                primaryBgColor: '#1e453e',
                primaryBgColorDark: '#306844',
                primaryBgColorLight: '#2c4c3b',
                primaryFgColor: '#fff',
                // Secondary
                secondaryBgColor: '#fff',
                secondaryFgColor: '#455b55',
                secondaryFgColorLight: '#fff',
                // splash screen
                splashScreenBgColor: '#040a06;',
                // toolbar-system
                toolbarSystemFgColor: '#040a06',
                toolbarSystemBgColor: '#97b3a1',
            } });
    };
    ThemeConfigService.prototype.configTheme = function (theme) {
        var _this = this;
        return this._http
            .put(environment.URL_API + "/projects/x/theme", { theme: theme })
            .pipe(map(function (res) { return res.data.theme; }), tap(function (configTheme) { return _this.setTheme(configTheme); }));
    };
    ThemeConfigService.prototype.requestSessionConfigTheme = function (code) {
        var _this = this;
        this._http
            .post(environment.URL_API + "/mod/participants/sessions/config/theme", { code: code })
            .pipe(map(function (res) { return res.data.theme; }))
            .subscribe(function (theme) { return _this.setTheme(theme); });
    };
    ThemeConfigService.prototype.setTheme = function (theme, forceDispatch) {
        if (forceDispatch === void 0) { forceDispatch = false; }
        var uuid = forceDispatch ? Math.random().toString() : theme.uuid;
        this.setState(__assign({}, this.state, { uuid: uuid, colors: Object.assign({}, this.state.colors, theme.colors), messages: Object.assign({}, this.state.messages, theme.messages), logos: Object.assign({}, this.state.logos, theme.logos) }));
    };
    ThemeConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ThemeConfigService_Factory() { return new ThemeConfigService(i0.inject(i1.HttpClient), i0.inject(i2.W3StorageService)); }, token: ThemeConfigService, providedIn: "root" });
    return ThemeConfigService;
}(SimpleStore));
export { ThemeConfigService };
