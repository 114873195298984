import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {fuseAnimations} from '@fuse/animations';
import {ResponseVoteModel} from '@sip/common/models';

@Component({
    selector: 'sip-shared-vote-hash-dialog',
    templateUrl: './vote-hash-dialog.component.html',
    animations: fuseAnimations
})
export class VoteHashDialogComponent implements OnInit{
    constructor(
        public dialogRef: MatDialogRef<VoteHashDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ResponseVoteModel) {
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.dialogRef.close();
        }, 7000);
    }
}
