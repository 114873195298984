import {Component, Input, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

@Component({
    selector: 'sip-shared-sessao-header',
    templateUrl: './sessao-header.component.html',
    styleUrls: ['./sessao-header.component.scss'],
    animations: fuseAnimations
})
export class SessaoHeaderComponent implements OnInit {

    @Input() icon: string;
    @Input() label: string;
    @Input() description?: string;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.icon) {
            this.icon = 'info_outline';
        }
        if (!this.label) {
            this.label = '';
        }
    }

}
