import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ParticipantService = /** @class */ (function () {
    function ParticipantService(http) {
        this.http = http;
    }
    ParticipantService.prototype.me = function (include) {
        return this.http
            .get(environment.URL_API + "/mod/participants/me", { params: { include: include } })
            .pipe(map(function (r) { return r.data; }));
    };
    ParticipantService.prototype.update = function (data) {
        return this.http
            .put(environment.URL_API + "/mod/participants/me", data)
            .pipe(map(function (r) { return r.data; }));
    };
    ParticipantService.prototype.updateStatus = function (status) {
        return this.http
            .put(environment.URL_API + "/mod/participants/me/status", { status: status })
            .pipe(map(function (r) { return r.data; }));
    };
    ParticipantService.ngInjectableDef = i0.defineInjectable({ factory: function ParticipantService_Factory() { return new ParticipantService(i0.inject(i1.HttpClient)); }, token: ParticipantService, providedIn: "root" });
    return ParticipantService;
}());
export { ParticipantService };
