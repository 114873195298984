import {Injectable} from '@angular/core';

import {W3StorageService} from '@rapi/w3/apps/storage';

import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {environment} from '../../../environments/environment';

interface DataObj {
    [key: string]: any;
}


export interface SwChannel {
    name: string;

    listen(event: string, func: (e) => void): SwChannel;
}

export interface SwInChannel<T> extends SwChannel {

    here(func: (users: T[]) => void): SwInChannel<T>;

    joining(func: (user: T) => void): SwInChannel<T>;

    leaving(func: (user: T) => void): SwInChannel<T>;

    listenForWhisper(eventName: string, func: (e: DataObj) => void): SwInChannel<T>;

    whisper(eventName: string, e: T): SwInChannel<T>;
}

@Injectable()
export class SipSocketService {

    private _echo;
    private _channels: string[] = [];

    constructor(private storage: W3StorageService) {
    }

    get echo(): any {
        return this._echo;
    }

    connect(): SipSocketService {

        if (this._echo) {
            return this;
        }

        const token = this.storage.get('access_token');
        const APP_KEY = environment.SOCKET_APP_KEY;

        // Enable pusher logging - don't include this in production
        Pusher.logToConsole = environment.DEBUG;

        const pusher = new Pusher(APP_KEY, {
            cluster: 'none',
            wsHost: environment.SOCKET_HOST,
            forceTLS: environment.SOCKET_FORCE_TLS,
            wsPort: environment.SOCKET_PORT,
            enableStats: false,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            channelAuthorization: {
                transport: 'ajax',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                endpoint: environment.SOCKET_URL_AUTH
            },
        });

        this._echo = new Echo({
            broadcaster: 'pusher',
            encrypted: true,
            client: pusher,
        });

        return this;
    }

    joinChannel<T>(channel: string): SwInChannel<T> {
        this._channels.push(channel);
        return this._echo.join(channel);
    }

    createPrivateChannel(channel: string): SwChannel {
        this._channels.push(channel);
        return this._echo.private(channel);
    }

    createPublicChannel(channel: string): SwChannel {
        this._channels.push(channel);
        return this._echo.channel(channel);
    }

    leave(ch: SwChannel): void {
        if (this._echo) {
            this._echo.leave(ch.name);
        }
    }

    reset(): void {

        if (this._echo) {
            this._channels.map(ch => this._echo.leave(ch));
            this._echo = null;
            this._channels = [];
        }
    }
}
