export class ConfigSessionModel {
    code?: number;
    evento?: string;
    nome?: string;
    type?: string;
    msg_org?: string;
    enable_forward?: boolean;
    apresentation_type?: string;
    enable_change_view?: boolean;
    enable_countdown?: boolean;
    has_feedback?: boolean;
}

export interface InputConfirmReply {
    inputType: QuestTypeEnum;
    reply_poll?: Reply[];
    reply_text?: string;
}

export interface Reply {
    empty?: boolean;
    label: string;
    value: string;
}

export enum QuestTypeEnum {
    QUEST_LONG_POLL = 'quest_long_poll',
    QUEST_POLL = 'quest_poll',
    QUEST_TEXT = 'quest_text'
}
