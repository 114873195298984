export enum GraphWhenShowEnum {
    MANUAL = 'manual', // manualmente
    CLOSED = 'closed', // quando encerrar votacao
    OPENED = 'opened', // quando abrir votacao
}

export enum GraphTypeEnum {
    // PIZZA = 'pizza',
    // PIZZA_3D = 'pizza_3d',
    BARRA = 'barra',
    // BARRA_3D = 'barra_3d',
    COLUNA = 'coluna',
    INDEPENDENTE = 'independente',
    // COLUNA_3D = 'coluna_3d',
    TEXT = 'text',
    NUMERICAL = 'numerical',
    COMPARATIVO = 'comparativo',

    RANKING_PARTICIPANT = 'ranking_participant',
    RANKING_GROUP = 'ranking_group',
    RANKING_SLIDE = 'ranking_slides',
    CLOUD_TAD = 'cloud_tag',
}

export enum GraphLabelEnum {
    NUM_VOTOS = 'num_votos', // # (número de votos)
    PERCENT_VOTOS = 'percent_votos', // #% (porcentagem de votos)
    NUM_PERCENT_VOTOS = 'nun_e_percent', // #% (número e porcentagem)
    SEM_LABEL = 'sem_label', //  Sem LABEL
    PESO = 'peso', // ## PESO
}

export interface GraphModel {
    id?: number;
    enable: boolean;
    question_id?: number;
    slide_id?: number;
    type: GraphTypeEnum;
    label: GraphLabelEnum;
    decimal_to_percentage: number;
    decimal_to_weight: number;
    visible: boolean;
    when_show: GraphWhenShowEnum;
    color: string; // hexadecimal
    weight: string | number; // expesura do grafico
    metas?: any;
    created_at?: string;
    updated_at?: string;
}


export interface GraphConfig {
    demo: boolean;
    show: boolean;
    type: GraphTypeEnum;
}

export interface GraphInteractComponent {
    setData(data: any): void;

    setColor(color: string): void;

    setWeight(weight: number): void;
}
