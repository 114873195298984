import * as moment from 'moment';
var AlertAllParticipants = /** @class */ (function () {
    function AlertAllParticipants(data) {
        this.data = data;
    }
    Object.defineProperty(AlertAllParticipants.prototype, "message", {
        get: function () {
            return this.data.message;
        },
        enumerable: true,
        configurable: true
    });
    return AlertAllParticipants;
}());
export { AlertAllParticipants };
var ProjectChangedConfig = /** @class */ (function () {
    function ProjectChangedConfig(data) {
        this.data = data;
    }
    Object.defineProperty(ProjectChangedConfig.prototype, "config", {
        get: function () {
            return this.data.theme;
        },
        enumerable: true,
        configurable: true
    });
    return ProjectChangedConfig;
}());
export { ProjectChangedConfig };
var MessageRegistered = /** @class */ (function () {
    function MessageRegistered(data, action) {
        this.data = data;
        this.action = action;
    }
    Object.defineProperty(MessageRegistered.prototype, "id", {
        get: function () {
            return +this.data.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageRegistered.prototype, "name", {
        get: function () {
            return this.data._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageRegistered.prototype, "isParticipant", {
        get: function () {
            return !this.isSpeaker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageRegistered.prototype, "isSpeaker", {
        get: function () {
            return this.data._from === 'speaker';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageRegistered.prototype, "time", {
        get: function () {
            return moment(this.data.updated_at).format('DD/MM/YY HH:mm') || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageRegistered.prototype, "message", {
        get: function () {
            return this.data.text;
        },
        enumerable: true,
        configurable: true
    });
    return MessageRegistered;
}());
export { MessageRegistered };
