import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ItemResponse} from '@rapi/w3';
import {W3StorageService} from '@rapi/w3/apps/storage';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SessionPrefetchService {

    constructor(private http: HttpClient, private storage: W3StorageService) {
    }

    getPrefetchAssets(code: string, mode: 'speaker' | 'participant'): Observable<any> {
        const input = {code, mode};

        return this.http
            .post(`${environment.URL_API}/p/sessions/prefetch-assets`, input)
            .pipe(map((r: ItemResponse) => r.data));
    }

    loadManual(sessionCode: string, mode: 'speaker' | 'participant'): void {
        const $body = document.getElementsByTagName('body')[0];
        const $el = document.createElement('div');
        $el.style.display = 'none';

        const addImg = (url) => {
            const img = new Image();
            img.src = url;
            $el.appendChild(img);
        };

        this.getPrefetchAssets(sessionCode, mode).subscribe(data => {
            data.assets
                .filter(url => !url.endsWith('mp4'))
                .forEach(url => addImg(url));

            $body.appendChild($el);
        });
    }

    startPrefetchAssets(sessionCode: string, mode: 'speaker' | 'participant'): void {
        if (!('caches' in window)) {
            this.loadManual(sessionCode, mode);
            return;
        }

        const fetchAndCache = (fileUrl, cache) => {
            // Check first if video is in the cache.
            return cache.match(fileUrl)
                .then(cacheResponse => {
                    // Let's return cached response if video is already in the cache.
                    if (cacheResponse) {
                        return cacheResponse;
                    }

                    // Otherwise, fetch the video from the network.
                    return fetch(fileUrl, {mode: 'no-cors'})
                        .then(networkResponse => networkResponse.arrayBuffer())
                        .then(
                            data => {
                                const response = new Response(data);
                                // Add the response to the cache and return network response in parallel.
                                cache.put(fileUrl, response.clone());
                                return response;
                                // Add the response to the cache and return network response in parallel.
                                // cache.put(fileUrl, networkResponse.clone());
                                // console.log('FETCH: request finish', fileUrl);
                                // return networkResponse;
                            },
                            error => {
                                console.log('FETCH: request error', error);
                            }
                        );
                });
        };

        this.getPrefetchAssets(sessionCode, mode).subscribe(data => {
            const assetUrl: string[] = data.assets;

            this.storage.set('CACHE', data.version);

            // Let's create a video pre-cache and store all first segments of videos inside.
            window.caches.open(`VIS_CACHE_SESSION_${sessionCode}`)
                .then(cache => Promise.all(assetUrl.map(fileUrl => fetchAndCache(fileUrl, cache))));
        });
    }

}
