<ng-container *ngIf="layout == 'vertical'">

    <div class="navbar-vertical">

        <div class="navbar-header">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div (click)="goToHome()" fxFlex="" class="logo" fxLayout="row">
                    <img [sipCommomLogoManager]="'primary_logo'"  class="h-52" src="assets/images/logos/logo-sm.png">
                    <span class="logo-text">Virtual Interactive Solutions</span>
                </div>

                <button mat-icon-button class="toggle-sidebar-folded"
                        (click)="toggleSidebarFolded()" fxHide.lt-lg>
                    <mat-icon>menu</mat-icon>
                </button>

                <button mat-icon-button class="toggle-sidebar-opened"
                        (click)="toggleSidebarOpened()" fxHide.gt-md>
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>

            <ng-container *ngIf="session">
                <div fxLayout="column" fxLayoutAlign="center center" class="mt-16 session-infos">
                    <span class="font-weight-500">{{session.event.name}}</span>
                    <span class="font-weight-500">{{session.name}}</span>
                </div><!-- // if session -->
            </ng-container>
        </div>

        <div class="navbar-content" fusePerfectScrollbar
             [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

            <ng-container *ngIf="participant">
                <div class="pl-24 pr-24 participant-infos">
                    <div class="account-box" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="mr-8 s-44">account_circle</mat-icon>
                        <div fxLayout="column">
                            <span>{{participant.name}}</span>
                            <b>#{{participant.personal_key}}</b>
                        </div>
                    </div>
                </div><!-- // if particpant -->
            </ng-container>

            <fuse-navigation layout="vertical"></fuse-navigation>
        </div>

        <div class="navbar-footer p-8" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="mr-8">update</mat-icon>
            V{{versionSystem}}
        </div>

    </div>

</ng-container>

